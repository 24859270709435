export const sendMessage = () => {
  const url = "https://whatsapp.com/channel/0029VaN0CAr6WaKjOBzJK20P";
  window.open(url, "_blank", "noopener,noreferrer");
};

export const sendMessageChat = () => {
  const url =
    'https://api.whatsapp.com/send?phone="5569308704"&text=Hola 👋, tengo algunas dudas 🤔 sobre el sitio web 🌐 de El Wero Bisnero y me preguntaba si podrías orientarme un poco al respecto. Saludos cordiales 🙏. ';
  window.open(url, "_blank", "noopener,noreferrer");
};
