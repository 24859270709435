export const DeliverablesCoaching = () => {

  return (
    <>
      <section className="lg:space-y-10 space-y-10 flex flex-col">
        <h4>
          Entregables{" "}
          <span className="font-normal text-colorGreen">
            Paquete 10 sesiones
          </span>
        </h4>

        <div className="flex md:flex-row flex-col items-center justify-between w-full md:space-x-16 md:space-y-0 space-y-10">
          <p className="xl:max-w-xl lg:max-w-lg md:max-w-md">
            <span className="font-medium text-colorGreen">Plan de Negocios Completo:</span> Un documento detallado que incluye la
            visión, misión, análisis de mercado, estrategia de marketing, plan
            operativo, estructura organizacional y proyecciones financieras de
            tu idea de negocio.
          </p>
          <div className="md:w-[488px]">
            <img src="/images/coaching/boli_compu.png" alt="Computadora" className="w-full object-cover"/>
          </div>
        </div>
      </section>
    </>
  );
};
