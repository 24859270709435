import { sendMessageChat } from "../../utils/SendWhatsapp"

export const ButtonWhatsapp = () => {
    return (
      <div className="fixed sm:bottom-10 bottom-4 sm:right-10 right-4 z-50">
        <button
          onClick={() => sendMessageChat()}
          className="btn-yellow sm:w-16 sm:h-16 w-11 h-11 rounded-full shadow-lg"
        >
          <i className="fab fa-whatsapp sm:text-3xl text-2xl" />
        </button>
      </div>
    );
  };