import { useNavigate } from "react-router-dom";
import { Logos } from "../../../assets/Logos";
import { motion } from "framer-motion";

export const ExpiredURL = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-notiwero bg-colorSand1 min-h-screen flex flex-col items-center justify-center relative">
        <div className="sm:w-28 w-20 absolute left-10 top-10">
          <Logos fill="#053F2E" />
        </div>
        <div className="space-y-5 px-10 max-w-2xl">
          <h1 className="sm:text-4xl text-3xl text-center text-colorGreen font-semibold">
            Enlace expirado
          </h1>
          <p className="text-center sm:text-xl text-base">
            El enlace ha caducado. Esto significa que ya se ha redirigido o que
            ha expirado la sesión.
          </p>
        </div>
        <motion.button
          onClick={() => {
            sessionStorage.removeItem("statusExpire");
            navigate("/");
          }}
          className="btn-green sm:w-72 w-60 sm:text-base text-sm mt-10"
        >
          Regresar al Wero Bisnero
        </motion.button>
      </div>
    </>
  );
};
