import SEO from "../../seo/SEO";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";

import { myBisness } from "../../utils/Data";

export default function Linktree() {
  return (
    <>
      <SEO
        title="Mis Bisness"
        description="Accede a todos los enlaces importantes de El Wero Bisnero y mantente al día con sus últimas novedades y recursos."
      />

      <Navbar
        color="#F3FF03"
        bgColor="bg-colorDarkBlue"
        colorTxt="text-colorSand"
        colorIcon="#F2EFDD"
      />

      <div className="bg-colorDarkBlue min-h-screen flex flex-row justify-center items-center md:py-36 py-28 md:px-10 px-6 bg-home">
        <div className="xl:w-[1200px] w-full">
          `
          <h3 className="font-noto text-center md:mb-10 mb-6 text-colorYellow">
            Mis Bisness
          </h3>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-10 gap-6">
            {myBisness.map((item, index) => (
              <a
                key={index}
                href={item.url}
                rel="noreferrer noopener"
                target="_blank"
                className=" bg-colorSand1 p-5 rounded-2xl hover:scale-110 transition-all duration-300 ease-in-out flex flex-col justify-center items-center h-56"
              >
                <div className={`${item.styleIcon}`}>
                  {item.icon ? (
                    item.icon
                  ) : (
                    <div className="text-colorGray font-noto text-5xl text-center">{item.red}</div>
                  )}
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
