export const MenuIcon = ({ fill }) => {
  return (
    <svg viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="3" y1="0.5" x2="21" y2="0.5" stroke={fill} />
      <line y1="6.5" x2="21" y2="6.5" stroke={fill} />
      <line x1="7" y1="12.5" x2="21" y2="12.5" stroke={fill} />
    </svg>
  );
};

export const PhoneIcon = ({ fill }) => {
  return (
    <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.0236 24.2818L23.2649 19.0458C22.9927 18.7984 22.635 18.6665 22.2673 18.6778C21.8996 18.6892 21.5507 18.843 21.2943 19.1068L17.9042 22.5932C17.0882 22.4373 15.4477 21.9259 13.7591 20.2415C12.0704 18.5514 11.559 16.9067 11.4074 16.0963L14.891 12.7048C15.1551 12.4486 15.3091 12.0997 15.3205 11.7319C15.3318 11.3641 15.1997 11.0063 14.9519 10.7343L9.71732 4.97693C9.46946 4.70401 9.12498 4.53847 8.75703 4.51546C8.38909 4.49245 8.02666 4.61378 7.74673 4.85368L4.67257 7.49009C4.42764 7.73591 4.28145 8.06306 4.26173 8.40951C4.24048 8.76368 3.83532 17.1532 10.3406 23.6613C16.0158 29.3351 23.1246 29.7502 25.0825 29.7502C25.3686 29.7502 25.5443 29.7417 25.5911 29.7388C25.9375 29.7195 26.2644 29.5726 26.5091 29.3266L29.1441 26.251C29.3849 25.972 29.5071 25.6098 29.4846 25.2419C29.4621 24.874 29.2967 24.5295 29.0236 24.2818Z"
        fill={fill}
      />
    </svg>
  );
};

export const MailIcon = ({ fill }) => {
  return (
    <a href="mailto:rsb@oblekco.com" target="_blank" rel="noreferrer noopener">
      <svg
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hover:scale-110 transform transition-all ease-in-out duration-300"
      >
        <path
          d="M28.333 5.66699H5.66634C4.91489 5.66699 4.19422 5.9655 3.66287 6.49686C3.13152 7.02821 2.83301 7.74888 2.83301 8.50033V25.5003C2.83301 26.2518 3.13152 26.9724 3.66287 27.5038C4.19422 28.0351 4.91489 28.3337 5.66634 28.3337H28.333C29.0845 28.3337 29.8051 28.0351 30.3365 27.5038C30.8678 26.9724 31.1663 26.2518 31.1663 25.5003V8.50033C31.1663 7.74888 30.8678 7.02821 30.3365 6.49686C29.8051 5.9655 29.0845 5.66699 28.333 5.66699ZM28.333 12.3253L16.9997 19.8818L5.66634 12.3253V8.92108L16.9997 16.4762L28.333 8.92108V12.3253Z"
          fill={fill}
        />
      </svg>
    </a>
  );
};

export const UserIcon = ({ fill }) => {
  return (
    <svg viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.762 23.2885C22.3749 24.293 24.2228 25.4436 25.5469 27.0322C18.3119 34.0773 7.27364 34.0773 0.0385742 27.0322C1.36268 25.4436 3.2106 24.293 4.82346 23.2885C6.80342 22.0538 8.51521 20.9873 8.51521 19.6508C8.54577 18.2073 7.95178 16.8297 6.90653 15.9204C5.96192 15.7522 5.46695 13.8493 5.46695 12.8049C5.41411 12.3509 5.56387 11.8968 5.87118 11.5792C5.18647 9.19396 5.19885 5.88387 6.75392 3.54288C7.97074 1.71083 9.97545 0.737252 12.5535 0.728373C14.3965 0.100429 16.4115 0.555765 17.8622 1.92767C19.0213 3.04729 20.7826 5.77765 19.706 11.5527C19.9288 11.7208 20.1433 12.0705 20.1433 12.805C20.1433 13.8494 19.6483 15.7567 18.6996 15.9204C17.6424 16.8223 17.0397 18.2026 17.0703 19.6509C17.0702 20.9873 18.7821 22.0538 20.762 23.2885Z"
        fill={fill}
      />
    </svg>
  );
};

export const CalendarIcon = ({ fill, secondFill }) => {
  return (
    <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="5.64062" width="18" height="13" fill={secondFill} />
      <path
        d="M19.5556 20.4406H2.44444V8.34063H19.5556V20.4406ZM15.8889 0.640625V2.84063H6.11111V0.640625H3.66667V2.84063H2.44444C1.08778 2.84063 0 3.81963 0 5.04063V20.4406C0 21.0241 0.257539 21.5837 0.715961 21.9963C1.17438 22.4088 1.79614 22.6406 2.44444 22.6406H19.5556C20.2039 22.6406 20.8256 22.4088 21.284 21.9963C21.7425 21.5837 22 21.0241 22 20.4406V5.04063C22 3.81963 20.9 2.84063 19.5556 2.84063H18.3333V0.640625H15.8889ZM17.1111 12.7406H11V18.2406H17.1111V12.7406Z"
        fill={fill}
      />
    </svg>
  );
};

export const CreditCardIcon = ({ fill }) => {
  return (
    <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.4047 17.8937L26.2644 18.7712V25.6367C26.2644 26.6296 25.5115 27.4372 24.5861 27.4372H2.22287C1.29755 27.4372 0.544678 26.6295 0.544678 25.6367V11.4257C0.544678 10.5025 1.19587 9.74042 2.03115 9.63788L22.4779 1.25511C23.3431 0.901405 24.3144 1.3671 24.6446 2.29438L25.9146 5.85935C25.9147 5.85977 25.915 5.86008 25.9151 5.8605C25.9153 5.86092 25.9153 5.86135 25.9155 5.86171L29.3734 15.5689C29.7035 16.4963 29.2689 17.5391 28.4047 17.8937Z"
        fill="#748596"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M4.25342 9.62396H15.6366C15.6371 9.62378 15.6375 9.62336 15.6381 9.62311L25.0449 5.76672L23.9142 2.5925C23.7377 2.09782 23.2189 1.84902 22.757 2.03773L4.25342 9.62396Z"
        fill="white"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M1.3269 11.4263V14.8242H25.4824V11.4263C25.4824 10.8962 25.0803 10.4648 24.5862 10.4648H2.22295C1.72889 10.4648 1.3269 10.8962 1.3269 11.4263Z"
        fill="white"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M25.4824 25.6361V15.6621H1.3269V25.6361C1.3269 26.1662 1.72894 26.5976 2.22295 26.5976H24.5862C25.0803 26.5976 25.4824 26.1662 25.4824 25.6361Z"
        fill="white"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M28.6427 15.8673L25.3242 6.55078L17.8276 9.6241H24.5862C25.5117 9.6241 26.2645 10.4318 26.2645 11.4246V17.8716L28.1255 17.1086C28.5871 16.9195 28.8191 16.3625 28.6427 15.8673Z"
        fill="white"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M12.4584 19.3758V22.8846C12.4584 23.6562 11.8732 24.2839 11.154 24.2839H4.94981C4.23057 24.2839 3.64551 23.6562 3.64551 22.8846V19.3758C3.64551 18.6042 4.23057 17.9766 4.94981 17.9766H11.154C11.8732 17.9766 12.4584 18.6042 12.4584 19.3758Z"
        fill="#748596"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M11.1539 18.8145H9.69189V20.7095H11.6763V19.3747C11.6763 19.0657 11.4419 18.8145 11.1539 18.8145Z"
        fill="white"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M4.42749 19.3747V20.7095H8.90973V18.8145H4.9497C4.66172 18.8145 4.42749 19.0658 4.42749 19.3747Z"
        fill="white"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M4.9497 23.4458H6.41176V21.5508H4.42749V22.8856C4.42749 23.1946 4.66172 23.4458 4.9497 23.4458Z"
        fill="white"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M11.6762 22.8856V21.5508H7.19385V23.4458H11.1539C11.4419 23.4458 11.6762 23.1946 11.6762 22.8856Z"
        fill="white"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M23.2795 19.566C23.2795 19.7977 23.1044 19.9856 22.8884 19.9856H15.6215C15.4055 19.9856 15.2305 19.7977 15.2305 19.566C15.2305 19.3344 15.4055 19.1465 15.6215 19.1465H22.8884C23.1044 19.1465 23.2795 19.3344 23.2795 19.566Z"
        fill="#748596"
        stroke="#748596"
        strokeWidth="0.5"
      />
      <path
        d="M22.8884 22.2754H15.6215C15.4055 22.2754 15.2305 22.4633 15.2305 22.6949C15.2305 22.9266 15.4055 23.1145 15.6215 23.1145H22.8884C23.1045 23.1145 23.2795 22.9266 23.2795 22.6949C23.2795 22.4633 23.1044 22.2754 22.8884 22.2754Z"
        fill="#748596"
        stroke="#748596"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const CheckIcon = ({ fill }) => {
  return (
    <>
      <svg
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4 8C14.4 9.69739 13.7257 11.3253 12.5255 12.5255C11.3253 13.7257 9.69739 14.4 8 14.4C6.30261 14.4 4.67475 13.7257 3.47452 12.5255C2.27428 11.3253 1.6 9.69739 1.6 8C1.6 6.30261 2.27428 4.67475 3.47452 3.47452C4.67475 2.27428 6.30261 1.6 8 1.6C8.608 1.6 9.2 1.688 9.76 1.848L11.016 0.592C10.088 0.208 9.072 0 8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8H14.4ZM4.728 6.464L3.6 7.6L7.2 11.2L15.2 3.2L14.072 2.064L7.2 8.936L4.728 6.464Z"
          fill="#F3FF03"
        />
      </svg>
    </>
  );
};

export const PlanFreeIcon = () => {
  return (
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" rx="8" fill="white" />
      <path
        d="M48.3779 27.6368C48.0819 26.8413 47.3236 26.3128 46.4748 26.3103H38.2357C37.9483 26.3105 37.6922 26.1292 37.5968 25.8582L34.6392 17.4755C34.3426 16.6821 33.5845 16.1562 32.7375 16.1562C31.8904 16.1562 31.1324 16.6821 30.8357 17.4755L30.8289 17.4972L27.8782 25.8582C27.7829 26.1287 27.5275 26.3099 27.2406 26.3103H18.9988C18.1442 26.3096 17.3807 26.8442 17.0889 27.6474C16.7971 28.4506 17.0395 29.3506 17.6953 29.8986L24.7122 35.7189C24.927 35.8972 25.0103 36.1891 24.922 36.4539L21.9739 45.294C21.6943 46.1327 21.9896 47.056 22.704 47.5767C23.4184 48.0975 24.3877 48.0962 25.1007 47.5734L32.3368 42.2675C32.5749 42.093 32.8987 42.093 33.1368 42.2675L40.3702 47.5721C41.0829 48.0967 42.0533 48.0992 42.7687 47.5784C43.4842 47.0576 43.7799 46.1334 43.4997 45.294L40.5516 36.4485C40.4633 36.1837 40.5466 35.8918 40.7614 35.7135L47.7918 29.885C48.4391 29.3325 48.6731 28.4349 48.3779 27.6368Z"
        fill="#5F8B66"
      />
      <mask
        id="mask0_2307_9511"
        maskUnits="userSpaceOnUse"
        x="16"
        y="16"
        width="33"
        height="32"
      >
        <path
          d="M48.3779 27.6368C48.0819 26.8413 47.3236 26.3128 46.4748 26.3103H38.2357C37.9483 26.3105 37.6922 26.1292 37.5968 25.8582L34.6392 17.4755C34.3426 16.6821 33.5845 16.1562 32.7375 16.1562C31.8904 16.1562 31.1324 16.6821 30.8357 17.4755L30.8289 17.4972L27.8782 25.8582C27.7829 26.1287 27.5275 26.3099 27.2406 26.3103H18.9988C18.1442 26.3096 17.3807 26.8442 17.0889 27.6474C16.7971 28.4506 17.0395 29.3506 17.6953 29.8986L24.7122 35.7189C24.927 35.8972 25.0103 36.1891 24.922 36.4539L21.9739 45.294C21.6943 46.1327 21.9896 47.056 22.704 47.5767C23.4184 48.0975 24.3877 48.0962 25.1007 47.5734L32.3368 42.2675C32.5749 42.093 32.8987 42.093 33.1368 42.2675L40.3702 47.5721C41.0829 48.0967 42.0533 48.0992 42.7687 47.5784C43.4842 47.0576 43.7799 46.1334 43.4997 45.294L40.5516 36.4485C40.4633 36.1837 40.5466 35.8918 40.7614 35.7135L47.7918 29.885C48.4391 29.3325 48.6731 28.4349 48.3779 27.6368Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2307_9511)"></g>
    </svg>
  );
};

export const PlanPremiumIcon = () => {
  return (
    <svg
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="8" fill="#DBFFDF" />
      <mask
        id="mask0_2336_6074"
        maskUnits="userSpaceOnUse"
        x="11"
        y="10"
        width="42"
        height="44"
      >
        <path
          d="M11.5723 53.5V10.5H52.4301V53.5H11.5723Z"
          fill="white"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask0_2336_6074)">
        <path
          d="M36.3175 50.9993C25.823 51.1874 15.9286 42.3115 14.2176 31.1761C13.1907 24.4865 15.3507 18.5025 19.513 14.75L17.2387 16.802C13.0749 20.5535 10.9153 26.5371 11.9433 33.2271C13.6542 44.3624 23.5486 53.2373 34.0432 53.0491C38.2317 52.9738 41.8853 51.4654 44.6521 48.9709L46.9265 46.921C44.1596 49.4156 40.5075 50.9239 36.3175 50.9993Z"
          fill="#F3FF03"
          stroke="#053F2E"
          strokeLinejoin="round"
        />
        <path
          d="M42.3003 28.5844C42.3537 28.3976 42.3545 28.1358 42.3028 27.7988L42.0801 26.3497C42.0076 25.8779 41.7818 25.6455 41.4027 25.6525L40.8496 25.6623L41.3453 28.8803L41.8832 28.8705C42.1077 28.8666 42.2467 28.7713 42.3003 28.5844Z"
          stroke="#053F2E"
          strokeLinejoin="round"
        />
        <path
          d="M43.1892 32.4631C43.1219 32.0252 43.0122 31.6949 42.8599 31.4722C42.7077 31.2495 42.4834 31.1403 42.1871 31.1447L41.6953 31.1534L42.3394 35.3503L42.6032 35.3626C43.2892 35.3503 43.5771 34.9847 43.4668 34.2658L43.1892 32.4631Z"
          stroke="#053F2E"
          strokeLinejoin="round"
        />
        <path
          d="M52.2228 30.4965C50.5122 19.3611 40.6178 10.4867 30.1239 10.6748C19.6301 10.8629 12.5079 20.042 14.2185 31.1774C15.9291 42.3127 25.8239 51.1875 36.3184 50.9994C46.813 50.8113 53.9338 41.6322 52.2228 30.4965ZM22.0252 38.284L19.7986 23.788L25.6231 23.6835L26.0519 26.483L23.1905 26.5343L23.6235 29.3524L26.5012 29.3007L26.9212 32.0343L24.0449 32.0871L24.5555 35.4113L27.4328 35.36L27.8659 38.1781L22.0252 38.284ZM34.6613 38.0551L32.9492 31.1046L33.4067 38.0795L30.2698 38.1359L26.6754 23.7105L29.1849 23.6654L31.3027 32.7694L31.206 27.4608L33.5958 27.4179L35.0968 32.7014L34.3833 23.5718L36.9225 23.5267L37.7369 38.0012L34.6613 38.0551ZM45.8233 36.6639C45.5948 37.2757 45.1327 37.659 44.437 37.8138L44.6169 38.9849L42.5097 39.0212L42.3401 37.9186L40.1515 37.9579L37.9281 23.4856L40.1168 23.4463L39.9503 22.364L42.0575 22.3277L42.2339 23.4627C42.8516 23.5529 43.3491 23.7845 43.7264 24.1573C44.241 24.6668 44.588 25.5052 44.7674 26.6726L44.8658 27.3131C45.0783 28.6945 44.7544 29.528 43.8942 29.8138C44.4716 29.9613 44.9169 30.3194 45.2302 30.8881C45.5435 31.4567 45.7722 32.2133 45.9164 33.1577C46.1525 34.6968 46.1216 35.866 45.8237 36.6653L45.8233 36.6639Z"
          fill="#F3FF03"
          stroke="#053F2E"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
