import { motion } from "framer-motion";
import moment from "moment/moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/es'; 

export const Schudle = ({ onNext, onPrevious }) => {
  moment.locale('es');
  const handleNext = () => {
    onNext();
  };

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <>
      <div className="space-y-10 pt-10">
        <div className=" ">
          <div className="flex flex-col items-start space-y-5">
            <div className="flex items-center">
              <motion.button
                whileTap={{ scale: 0.9 }}
                className="md:text-[40px] text-[28px]"
                onClick={() => handlePrevious()}
              >
                <i className="fas fa-angle-left text-colorGreen mr-3" />
              </motion.button>
              <span className="font-light tracking-[-1.5px] font-noto md:text-[40px] text-[28px]">
                Agenda tus sesiones
              </span>
            </div>
            <p className="text-lg md:pl-11">
              Por favor, selecciona una sesión por semana en los horarios
              disponibles.
            </p>
          </div>
        </div>

        <div className="flex md:flex-row flex-col justify-between lg:px-20 md:px-10 space-y-10">
          <div className="space-y-6">
            {data.map((item, index) => (
              <div className="flex flex-col space-y-2" key={index}>
                <p className="font-semibold"> {item.week}</p>
                <ul className="list-disc ml-8">
                  <li className="text-[18px]"> {item.shudle}</li>
                </ul>
              </div>
            ))}
          </div>
          <div>
            <Calendario />

            <div className="flex md:justify-end justify-start pt-20">
              <button className="btn-green w-[320px]" onClick={() => handleNext()}>Continuar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Calendario = () => {
  const localizer = momentLocalizer(moment);
  return (
    <>
      <div className="md:w-[50vw] md:h-[50vh] w-full h-[450px] ">
        <Calendar
          localizer={localizer}
          defaultView="month"
          toolba={false}
          views={["month"]}
        />
      </div>
    </>
  );
};

const data = [
  {
    week: "Semena 1",
    shudle: "Martes, 16 de marzo 12:00pm - 1:30pm",
  },
  {
    week: "Semena 2",
    shudle: "Martes, 16 de marzo 12:00pm - 1:30pm",
  },
  {
    week: "Semena 3",
    shudle: "Martes, 16 de marzo 12:00pm - 1:30pm",
  },
  {
    week: "Semena 4",
    shudle: "Martes, 16 de marzo 12:00pm - 1:30pm",
  },
  {
    week: "Semena 5",
    shudle: "Martes, 16 de marzo 12:00pm - 1:30pm",
  },
  {
    week: "Semena 6",
    shudle: "Martes, 16 de marzo 12:00pm - 1:30pm",
  },
];
