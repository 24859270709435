import Footer from "../../components/common/Footer";
import { ButtonsActions } from "../../components/home/ButtonsActions";
import { ButtonWhatsapp } from "../../components/home/ButtonWhatsapp";
import { HeroHome } from "../../components/home/HeroHome";
import { HistoryHome } from "../../components/home/HistoryHome";
import { VideoHome } from "../../components/home/VideoHome";
import SEO from "../../seo/SEO";

export default function Home() {
  return (
    <>
      <SEO
        title="Home"
        description="Explora el fascinante universo del emprendimiento junto a El Wero Bisnero. Eleva tu éxito con nuestro contenido personalizado."
      />
      <main className="sm:py-14 py-10 bg-colorDarkBlue bg-home flex flex-col items-center justify-center relative">
        <div className="lg:max-w-6xl px-6 min-h-screen w-full md:space-y-32 sm:space-y-28 space-y-20">
          <HeroHome />
          <VideoHome />
          <HistoryHome />
          <ButtonsActions />
        </div>
      </main>
      <ButtonWhatsapp />
      <Footer />
    </>
  );
}
