import { Logos } from "../../assets/Logos";

import { motion } from "framer-motion";

export const CommingSonn = ({ title }) => {
  return (
    <>
      <div className="bg-notiwero bg-colorSand1 flex flex-col justify-center items-center h-screen ">
        <div className="z-10 flex flex-col items-center">
          <h3 className="lg:text-2xl sm:text-xl text-lg text-colorGrayfont-work sm:mb-3 mb-1 ">
            Algo grandioso está en camino
          </h3>
          <h2 className="lg:text-5xl sm:text-4xl text-3xl text-colorGray font-work font-medium ">
            Próximamente
          </h2>
          <h1 className="text-colorGreen font-kaushan lg:text-[150px] sm:text-[130px] text-6xl text-center sm:my-28 my-14">
            {title}
          </h1>

          <motion.a
            whileTap={{ scale: 0.90 }}
            href="/"
            className="btn-green w-[200px] h-11 uppercase text-sm"
          >
            Inicio
          </motion.a>
        </div>

        <div className="absolute left-7 top-7 ">
          <div className="sm:w-[120px] w-[100px]">
            <Logos fill="#053F2E" />
          </div>
        </div>
      </div>
    </>
  );
};
