import ReactModal from "react-modal";
import animationData from "../../assets/jsons/check.json";
import Lottie from "lottie-react";
import { motion } from "framer-motion";


export const ModalTemplate = ({ isOpen, onClose, children, className}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
    >
      <div className="bg-colorSand1 md:p-10 p-5 rounded-2xl w-full max-w-xl shadow-xl flex flex-col items-center justify-center space-y-12 relative">
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={onClose}
          className={`absolute top-4 right-4 bg-colorGreen rounded-full w-6 h-6 ${className}`}
        >
          <i className="fas fa-xmark text-sm text-colorSand1" />
        </motion.button>
        <div className="w-full flex flex-col items-center justify-center">
          {children}
        </div>
      </div>
    </ReactModal>
  );
};


export const ModalConfirmationTemplate = ({ isOpen, onClose, children,className }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
    >
      <div className="bg-colorSand1 p-10 rounded-2xl w-full max-w-xl shadow-xl flex flex-col items-center justify-center space-y-12 relative">
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={onClose}
          className={`absolute top-4 right-4 bg-colorGreen rounded-full w-6 h-6 ${className}`}
        >
          <i className="fas fa-xmark text-sm text-colorSand1" />
        </motion.button>
        <div className="space-y-5 w-full flex flex-col items-center justify-center">
          <div className="mb-3 sm:w-32 w-20">
            <Lottie
              animationData={animationData}
              loop={false}
              autoPlay={true}
            />
          </div>
          {children}
        </div>
        <motion.a
          href="/notiwero"
          whileTap={{ scale: 0.9 }}
          className="btn-green text-colorGreen text-lg w-44"
        >
          Aceptar
        </motion.a>
      </div>
    </ReactModal>
  );
};
