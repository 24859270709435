//URL de  desarrollo
// const baseURLFetch = 'http://localhost:3010'

//URL de produccion prueba
// const baseURLFetch = 'http ://elwerobisnero.com:3010'

//URL de produccion
const baseURLFetch = 'https://elwerobisnero.com/api'

// URLs fetch GET
export const getPlansURL = () => {
    return `${baseURLFetch}/product`
}


// URLs fetch POST

export const postSuscriptionMonthURL = () => {
    return `${baseURLFetch}/payment/create-session`
}

export const postSuscriptionFreeURL = () => {
    return `${baseURLFetch}/payment/free-create-session`
}

export const postCancelSuscriptionURL = (email) => {
    return `${baseURLFetch}/subscription/request-cancel?email=${email}`
}

export const postUpdateEmailURL = (email) => {
    return `${baseURLFetch}/subscription/request-email-update?email=${email}`
}

export const postSendUpdateEmailURL = () => {
    return `${baseURLFetch}/subscription/verify-code-update`
}

export const postSendCancelEmailURL = () => {
    return `${baseURLFetch}/subscription/verify-code-cancel`
}