import { motion } from "framer-motion";
import { Logos } from "../../../../assets/Logos";

export const SuccesPay = () => {
  return (
    <>
      <div className="min-h-screen bg-colorSand1">
        <div className="flex lg:px-20 px-6 py-8 ">
          <a href="/inicio" rel="noreferrer noopener">
            <motion.div
              whileTap={{ scale: 0.9 }}
              className="md:w-[101px] w-[80px]"
            >
              <Logos fill="#053F2E" />
            </motion.div>
          </a>
        </div>

        <div className="flex flex-col items-center justify-center w-full md:space-y-10 space-y-7 lg:px-20 px-6 py-8">
          <div className="space-y-3 text-center">
            <h2>¡Felicidades!</h2>
            <h4>Tu pago se realizó con éxito</h4>
          </div>

          <div className="text-center">
            <p>Te mostramos el resumen de tu nuevo y emocionante viaje.</p>
            <p>
              También te enviamos todos los detalles a tu correo electrónico.
            </p>
          </div>

          <TableInfo />

          <div className="flex md:flex-row md:justify-end w-full">
            <div className="md:w-[37%] w-full space-y-5">
              <p className="md:text-xl text-base font-semibold">Total del carrito</p>

              <div>
                <div className="flex justify-between py-3">
                  <p className="md:text-xl text-base font-medium">Subtotal</p>
                  <p className="md:text-xl text-base">$49,500</p>
                </div>
                <div className="flex justify-between py-3 border-y border-colorGreen">
                  <p className="md:text-xl text-base font-medium">Envío</p>
                  <p className="md:text-xl text-base">$0</p>
                </div>
                <div className="flex justify-between py-3 border-b border-colorGreen">
                  <p className="md:text-xl text-base font-medium">Total</p>
                  <p className="md:text-xl text-base">$49,500</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex md:flex-row md:justify-center flex-col md:space-x-8 md:space-y-0 space-y-4 w-full">
            <div>
              <button className="btn-green md:w-[255px] w-full">Contacta al Wero</button>
              <p className="text-xs text-center pt-2">*Se te redireccionará a WhatsApp*</p>
            </div>
            <button className="btn-line md:w-[255px] w-full">Regresar al sitio web</button>
          </div>
        </div>
      </div>
    </>
  );
};

const TableInfo = () => {
  return (
    <table className="items-center w-full border-collapse">
      <thead className="font-work font-semibold md:text-xl text-base border border-x-0 border-colorGreen">
        <tr>
          <td className="px-2 py-3 text-left">Producto</td>
          <td className="px-2 py-3 text-center">Cantidad</td>
          <td className="px-2 py-3 text-center">Precio</td>
          <td className="px-2 py-3 text-center">Total</td>
        </tr>
      </thead>

      <tbody className="font-work md:text-xl text-base border border-x-0 border-colorGreen">
        <tr>
          <td className="px-2 py-4">
            Coaching uno a uno - Presencial #58631
            <br />
            Del 01/01/24 al 15/03/24
          </td>
          <td className="px-2 py-4 text-center">1</td>
          <td className="px-2 py-4 text-center">$49,500</td>
          <td className="px-2 py-4 text-center">$49,500</td>
        </tr>
      </tbody>
    </table>
  );
};
