export const HeroCoaching = () => {
  return (
    <section className="flex md:flex-row flex-col justify-between lg:space-y-0 space-y-10 md:space-x-10">
      <div className="lg:space-y-11 space-y-5 ">
        <h3 className="">
          Coaching{" "}
          <span className="text-colorGreen font-normal">uno a uno</span>
        </h3>

        <div className="md:max-w-xl space-y-7">
          <p>
            ¿Tienes una idea que podría ser{" "}
            <span className="text-colorGreen font-semibold">
              la próxima gran cosa en el mundo de los negocios
            </span>
            , pero no sabes cómo hacerla realidad?
          </p>
          <p>
            Escoge entre mis paquetes de{" "}
            <span className="text-colorGreen font-semibold">10 sesiones</span>,
            y juntos haremos que tu idea no sólo despegue, sino que vuele alto.
          </p>
        </div>
        <a href="/coaching/coaching-uno-a-uno/cuentame-de-ti" className="btn-green w-[214px]">
          ¡Quiero volar!
        </a>
      </div>

      <div className="lg:w-[742px] ">
        <img
          src="/images/coaching/hero1.png"
          alt="Coaching"
          draggable="false"
          className="w-full object-cover"
        />
      </div>
    </section>
  );
};
