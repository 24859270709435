import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

// export const ProtectedRoutePlans = ({ children }) => {
//   const { paramURL } = useParams();
//   const plan = sessionStorage.getItem("plan");

//   if (!plan) {
//     return <Navigate to="/notiwero" />;
//   }
//   return paramURL === plan ? children : <Navigate to="*" />;
// };

export const ProtectedRouteNotiwero = ({
  children,
  storageKey,
  expiredTime,
}) => {
  const { paramURL } = useParams();
  const navigate = useNavigate();

  const constStorage = sessionStorage.getItem(storageKey);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      sessionStorage.removeItem(storageKey);
      navigate("/notiwero/link-expirado");
      sessionStorage.setItem("statusExpire", "true");
    }, expiredTime);
    return () => clearTimeout(timer);
  }, [navigate, storageKey, expiredTime]);

  if (!constStorage) {
    return <Navigate to={`/notiwero/link-expirado`} />;
  }

  return paramURL === constStorage ? children : <Navigate to="*" />;
};

export const ProtectedRouteExpired = ({ children }) => {
  const navigate = useNavigate();
  const statusTemporality = sessionStorage.getItem("statusExpire");

  useEffect(() => {
    const timer = setTimeout(() => {
      sessionStorage.removeItem("statusExpire");
      navigate("/notiwero");
    }, 30000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return statusTemporality ? children : <Navigate to="/notiwero" />;
};
