export const LoaderRing = () => {
    return (
      <div className="min-h-full flex justify-center items-center">
        <div className="border-colorSand1 h-5 w-5 animate-spin rounded-full border-2 border-t-colorGreen" />
      </div>
    );
  };
  
  export const LoaderDots = () => {
    return (
      <div className="min-h-full flex justify-center items-center">
        <div className="flex space-x-1">
          <div className="h-2 w-2 bg-colorGreen rounded-full animate-bounce [animation-delay:-0.3s]" />
          <div className="h-2 w-2 bg-colorGreen rounded-full animate-bounce [animation-delay:-0.15s]" />
          <div className="h-2 w-2 bg-colorGreen rounded-full animate-bounce" />
        </div>
      </div>
    );
  };