import { useEffect, useState } from "react";
import { Logos } from "../../assets/Logos";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { MenuIcon } from "../../assets/Icons";

export default function Navbar({ color, bgColor, colorTxt, colorIcon }) {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setHasScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`z-40 w-full`}>
      <nav
        className={`md:py-6 py-5 lg:px-20 px-6 ${
          hasScrolled ? bgColor : "bg-transparent"
        } fixed z-10 flex items-center justify-between w-full`}
      >
        <a href="/" rel="noreferrer noopener">
          <motion.div
            whileTap={{ scale: 0.9 }}
            className="md:w-[101px] w-[80px]"
          >
            <Logos fill={color} />
          </motion.div>
        </a>
        <div className=" flex flex-row items-center">
          <motion.button
            onClick={() => setIsOpen(!isOpen)}
            whileTap={{ scale: 0.85 }}
            className={`${colorTxt} font-noto uppercase md:text-[32px] text-[28px] font-light`}
          >
            Menú
            <div className="w-5 ml-3">
              <MenuIcon fill={colorIcon} />
            </div>
          </motion.button>
        </div>
      </nav>

      <div
        className={`${
          isOpen ? "fixed inset-0 overflow-hidden" : "hidden"
        } w-full min-h-screen bg-colorGreen/95 z-50`}
      >
        <div className="flex flex-col items-center justify-center min-h-screen relative">
          <img
            src="/images/app/bg-menu.png"
            alt="Background menu"
            draggable="false"
            className="absolute h-screen lg:block hidden"
          />

          <img
            src="/images/app/bg-menu1.png"
            alt="Background menu"
            draggable="false"
            className="absolute w-full lg:hidden block"
          />
          <motion.button
            onClick={() => setIsOpen(!isOpen)}
            whileTap={{ scale: 0.85 }}
            className="absolute md:right-40 md:top-28 sm:right-10 sm:top-6 top-20 right-5"
          >
            <i className="fas fa-xmark text-colorYellow sm:text-6xl text-5xl" />
          </motion.button>

          <div className="md:space-y-16 space-y-4">
            <NavLink to="/el-wero">El Wero</NavLink>
            <NavLink to="/wero-wisdom"> Wero Wisdom </NavLink>
            <NavLink to="/notiwero">Notiwero</NavLink>
            <NavLink to="/tienda">WTF</NavLink>
            <NavLink to="/mis-bisness">Mis Bisness</NavLink>
          </div>
        </div>
      </div>
    </header>
  );
}

function NavLink({ to, children }) {
  const location = useLocation();

  return (
    <motion.a
      whileTap={{ scale: 0.9 }}
      href={to}
      rel="noopener noreferrer"
      className={`hover:text-green-700 transition-colors duration-300 md:text-[60px] sm:text-5xl text-[28px] font-noto text-colorGreen relative ${
        location.pathname === to ? "text-green-700 font-normal" : "font-light"
      }`}
    >
      {children}
    </motion.a>
  );
}