export const FacebookIcon = ({ fill, color }) => {
  return (
    <a
      href="https://www.facebook.com/elwerobisnero"
      target="_blank"
      rel="noreferrer noopener"
    >
      <svg
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hover:scale-110 transform transition-all ease-in-out duration-300"
      >
        <rect
          x="6.13379"
          y="6.13379"
          width="33.7333"
          height="33.7333"
          rx="6"
          fill={fill}
        />
        <path
          d="M24.757 39.8669V25.9011H29.7523L30.4948 20.433H24.757V16.9501C24.757 15.3722 25.2231 14.2919 27.6241 14.2919H30.6665V9.41686C29.1868 9.26691 27.6982 9.19534 26.2095 9.20045C21.7941 9.20045 18.7626 11.7428 18.7626 16.41V20.4228H13.7998V25.8909H18.7734V39.8669H24.757Z"
          fill={color}
        />
      </svg>
    </a>
  );
};
export const InstagramIcon = ({ fill, color }) => {
  return (
    <a
      href="https://www.instagram.com/elwerobisnero/"
      target="_blank"
      rel="noreferrer noopener"
    >
      <svg
        viewBox="0 0 47 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hover:scale-110 transform transition-all ease-in-out duration-300"
      >
        <rect
          x="12.5332"
          y="10.7334"
          width="23.5"
          height="24.5333"
          fill={color}
        />
        <path
          d="M41.0212 15.9172C40.9997 14.4663 40.7255 13.0269 40.2007 11.6699C39.2822 9.35264 37.412 7.52031 35.0444 6.62331C33.6755 6.11922 32.2283 5.84897 30.7635 5.81831C28.8796 5.73589 28.2823 5.71289 23.5 5.71289C18.7178 5.71289 18.1048 5.71289 16.2346 5.81831C14.7717 5.84897 13.3245 6.11922 11.9556 6.62331C9.58802 7.52031 7.71585 9.35264 6.79935 11.6699C6.28431 13.0096 6.00622 14.4261 5.97881 15.8578C5.8946 17.7036 5.86914 18.2881 5.86914 22.9686C5.86914 27.6491 5.86914 28.2471 5.97881 30.0795C6.00818 31.5131 6.28431 32.9276 6.79935 34.2712C7.71781 36.5866 9.58802 38.4189 11.9576 39.3159C13.3206 39.8372 14.7678 40.1324 16.2366 40.1784C18.1224 40.2608 18.7197 40.2857 23.502 40.2857C28.2842 40.2857 28.8972 40.2857 30.7674 40.1784C32.2303 40.1496 33.6775 39.8794 35.0483 39.3753C37.4159 38.4764 39.2861 36.6441 40.2046 34.3287C40.7196 32.9871 40.9958 31.5726 41.0251 30.137C41.1093 28.2931 41.1348 27.7086 41.1348 23.0261C41.1309 18.3456 41.1309 17.7515 41.0212 15.9172ZM23.4883 31.8198C18.4867 31.8198 14.4349 27.8542 14.4349 22.9591C14.4349 18.0639 18.4867 14.0983 23.4883 14.0983C28.4859 14.0983 32.5416 18.0639 32.5416 22.9591C32.5416 27.8542 28.4859 31.8198 23.4883 31.8198ZM32.902 15.8367C31.7329 15.8367 30.7909 14.9129 30.7909 13.7706C30.7909 12.6282 31.7329 11.7044 32.902 11.7044C34.0672 11.7044 35.0111 12.6282 35.0111 13.7706C35.0111 14.9129 34.0672 15.8367 32.902 15.8367Z"
          fill={fill}
        />
        <path
          d="M23.4883 28.7156C26.7362 28.7156 29.3692 26.1387 29.3692 22.9599C29.3692 19.781 26.7362 17.2041 23.4883 17.2041C20.2404 17.2041 17.6074 19.781 17.6074 22.9599C17.6074 26.1387 20.2404 28.7156 23.4883 28.7156Z"
          fill={fill}
        />
      </svg>
    </a>
  );
};
export const TwitterIcon = ({ fill, color }) => {
  return (
    <a
      href="https://x.com/notiwero"
      target="_blank"
      rel="noreferrer noopener"
    >
      <svg
        viewBox="0 0 47 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hover:scale-110 transform transition-all ease-in-out duration-300"
      >
        <path
          d="M24.2833 39.8663C34.2336 39.8663 42.2999 31.9716 42.2999 22.2329C42.2999 12.4943 34.2336 4.59961 24.2833 4.59961C14.3329 4.59961 6.2666 12.4943 6.2666 22.2329C6.2666 31.9716 14.3329 39.8663 24.2833 39.8663Z"
          fill={fill}
        />
        <path
          d="M13.1851 12.0166L21.7969 23.2861L13.1309 32.4488H15.0814L22.6687 24.4266L28.7987 32.4488H35.436L26.3395 20.5455L34.4059 12.0166H32.4554L25.4681 19.4047L19.8224 12.0166H13.1851ZM16.0535 13.4226H19.1026L32.5673 31.0428H29.5182L16.0535 13.4226Z"
          fill={color}
        />
      </svg>
    </a>
  );
};
export const YouTubeIcon = ({ fill, color }) => {
  return (
    <a
      href="https://www.youtube.com/@elwerobisnero"
      target="_blank"
      rel="noreferrer noopener"
    >
      <svg
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hover:scale-110 transform transition-all ease-in-out duration-300"
      >
        <path
          d="M31.572 39.8671H14.4289C9.84794 39.8671 6.13379 36.1531 6.13379 31.5711V14.4286C6.13379 9.84782 9.84794 6.13379 14.4289 6.13379H31.572C36.153 6.13379 39.8671 9.84782 39.8671 14.4286V31.5711C39.8683 36.1531 36.1541 39.8671 31.572 39.8671Z"
          fill={fill}
        />
        <path
          d="M34.7677 17.7438C34.628 17.2813 34.3552 16.8596 33.9762 16.5205C33.5973 16.1815 33.1255 15.9369 32.6078 15.8112C30.6882 15.3407 23.0086 15.333 23.0086 15.333C23.0086 15.333 15.3301 15.3254 13.4093 15.7751C12.8919 15.9066 12.4211 16.1546 12.042 16.4953C11.6629 16.836 11.3883 17.258 11.2446 17.7208C10.7383 19.4344 10.7334 22.9887 10.7334 22.9887C10.7334 22.9887 10.7285 26.5605 11.2311 28.2567C11.513 29.1945 12.3404 29.9353 13.3922 30.1881C15.3314 30.6587 22.9902 30.6663 22.9902 30.6663C22.9902 30.6663 30.6698 30.674 32.5894 30.2253C33.1073 30.0999 33.5795 29.8559 33.9594 29.5175C34.3393 29.1792 34.6137 28.7581 34.7554 28.2961C35.2629 26.5835 35.2666 23.0303 35.2666 23.0303C35.2666 23.0303 35.2911 19.4574 34.7677 17.7438ZM20.5521 26.2815L20.5582 19.7157L26.9409 23.0041L20.5521 26.2815Z"
          fill={color}
        />
      </svg>
    </a>
  );
};
export const TikTokIcon = ({ fill, color }) => {
  return (
    <a
      href="https://www.tiktok.com/@elwerobisnero"
      target="_blank"
      rel="noreferrer noopener"
    >
      <svg
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hover:scale-110 transform transition-all ease-in-out duration-300"
      >
        <path
          d="M31.572 39.8671H14.4289C9.84794 39.8671 6.13379 36.1531 6.13379 31.5711V14.4286C6.13379 9.84782 9.84794 6.13379 14.4289 6.13379H31.572C36.153 6.13379 39.8671 9.84782 39.8671 14.4286V31.5711C39.8683 36.1531 36.1541 39.8671 31.572 39.8671Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9275 28.9784C23.6727 30.744 22.1596 32.0869 20.3436 32.0891C19.7723 32.0867 19.2096 31.9491 18.7015 31.6873C18.1934 31.4256 17.7542 31.0472 17.4198 30.583C17.9355 30.8524 18.5091 30.9964 19.0909 31.0011C21.0389 30.9976 22.637 29.452 22.7089 27.5012V10.1973H27.0279C27.0243 10.562 27.0544 10.9262 27.118 11.2853H27.1183C27.2672 12.086 27.5774 12.8478 28.0299 13.5244C28.4824 14.201 29.0677 14.7781 29.7501 15.2206C29.7718 15.2454 29.7936 15.2701 29.8157 15.2946C29.7936 15.2697 29.7718 15.2447 29.7501 15.2194C30.7178 15.8638 31.8546 16.2076 33.0169 16.2076V17.1441C33.0196 17.1447 33.0223 17.1452 33.025 17.1458V20.4947C30.8736 20.4992 28.7752 19.8257 27.0268 18.5694V27.3677C27.0175 31.7582 23.4633 35.3125 19.0804 35.3137C17.4603 35.3453 15.87 34.8744 14.5273 33.9656C14.4375 33.8707 14.3503 33.7738 14.2657 33.675C11.6124 31.6552 10.4932 28.1715 11.5015 24.9641C12.5433 21.6501 15.6131 19.3985 19.0816 19.4055C19.4399 19.4105 19.7973 19.4411 20.1512 19.4972V20.5191C20.0993 20.5202 20.0475 20.5219 19.9957 20.524C20.0443 20.5223 20.0931 20.521 20.142 20.5202V23.919C19.7955 23.811 19.435 23.7553 19.0723 23.7518C18.4149 23.7549 17.7707 23.937 17.2087 24.2788C16.6467 24.6206 16.1881 25.1092 15.882 25.6921C14.9491 27.4676 15.6293 29.6645 17.4012 30.5993C17.7228 31.0439 18.1407 31.4097 18.6236 31.6691C19.1066 31.9286 19.6419 32.075 20.1895 32.0972C22.0681 32.1735 23.67 30.8051 23.9275 28.9784Z"
          fill={color}
        />
      </svg>
    </a>
  );
};
