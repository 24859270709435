export const NotiDescirption = () => {
  return (
    <>
      <section className="flex flex-col items-center justify-center">
        <div className="max-w-5xl space-y-7">
        <p>
          ¿Te cuesta estar al día con las últimas tendencias en negocios y
          emprendimiento?{" "}
          <span className="font-medium text-colorGreen">
            ¡Tranquilo, para eso está El NotiWero!
          </span>
        </p>
        <p>
          Cada día recibirás en tus manos información relevante, cruda y directa
          que necesitas para tomar{" "}
          <span className="font-medium text-colorGreen">
            decisiones inteligentes en tu negocio
          </span>{" "}
          y estar siempre un paso adelante. ¿Por qué perderse en un mar de
          información cuando puedes tener lo relevante en un solo lugar?
        </p>
        <p>
          Suscríbete a El NotiWero y conviértete en{" "}
          <span className="font-medium text-colorGreen">
            el emprendedor más informado
          </span>{" "}
          del barrio. Porque para ser un tiburón en los negocios, primero
          necesitas saber dónde están los peces.
        </p>
        </div>
      </section>
    </>
  );
};
