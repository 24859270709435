import { LoaderRing } from "../../components/common/Loaders";

export const Form = ({
  children,
  onSubmit,
  textButton,
  classButton,
  containerButton,
  isValid,
  styleIsValid,
  loading,
  classForm,
}) => {

  return (
    <form className={`${classForm}`} onSubmit={onSubmit}>
      {children}
      <div className={`${containerButton}`}>
        <button
          type="submit"
          className={`rounded-lg sm:text-[15px] text-sm transition-colors duration-300 ease-in-out px-5 relative ${classButton} ${
            isValid ? ` ${styleIsValid}` : "cursor-not-allowed opacity-60 "
          }`}
          disabled={!isValid || loading}
        >
          {loading ? "Enviando" : `${textButton}`}{" "}
          <span className="absolute top-1/2 right-4 transform -translate-y-1/2">
            {loading ? <LoaderRing /> : <i className="fas fa-lock text-sm" />}
          </span>
        </button>
      </div>
    </form>
  );
};