import { useForm } from "react-hook-form";
import { Logos } from "../../../assets/Logos";
import { useGetProducts } from "../../../hooks/useFetch/useGetProducts";
import { usePostCreateSession } from "../../../hooks/useFetch/usePostCreateSession";
import { Form } from "../../common/Form";
import { Input } from "../../common/ElementsForm/Input";

export const ViewSuscriptionMonth = () => {
  return (
    <>
      <div className="bg-colorSand1 min-h-screen w-full flex flex-col items-center md:py-4 py-8 px-6">
        <div className="md:w-[101px] w-[80px] md:mb-32 mb-20">
          <Logos fill="#053F2E" />
        </div>

        <div className="w-[250px] mb-10">
          <img
            src="/images/notiwero/newslatter_email.png"
            alt="Imagen newslatter"
            className="object-cover w-full"
          />
        </div>

        <div className="space-y-8 flex flex-col justify-center items-center w-full">
          <h4 className="md:text-3xl text-2xl font-bold text-center">
            {" "}
            El Newsletter del <span className="text-colorGreen">
              NotiWero
            </span>, <br /> documentos especializados de info que realmente
            importa{" "}
          </h4>

          <p className="md:text-base text-sm text-center">
            Ingresa tu correo electrónico y súmate a los emprededuros
            informados.
          </p>

          <PlanFormPremium />
        </div>
      </div>
    </>
  );
};

 const PlanFormPremium = () => {

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm({ mode: "onChange" });

  const { products } = useGetProducts();
  const product = products.find((p) => p.name === "Notiwero Plan Mensual");
  const { id } = product?.prices[0] || {};
  const { sendData, loading, serverError } = usePostCreateSession();

  return (
    <div className="xl:w-2/3 lg:w-[90%] md:w-[95%] sm:w-[85%] w-full">
      <Form
        onSubmit={handleSubmit((data) => sendData(data, reset, id))}
        textButton="Suscribirse"
        classForm="flex flex-col space-y-10"
        classButton="bg-colorGreen sm:w-[255px] w-[220px] text-white"
        containerButton="flex w-full justify-center items-center"
        styleIsValid="hover:border-2 hover:border-colorGreen hover:bg-transparent hover:text-colorGreen"
        isValid={isValid}
        loading={loading}
      >
        <div className="flex justify-center w-full">
          <Input
            name="userEmail"
            type="email"
            placeholder="Escribe aquí tu correo electrónico"
            textLabel="Correo electrónico"
            register={register}
            errors={{ ...errors, serverError }}
            classInput="input-line-green text-colorGray"
            classLabel="text-colorGreen hidden"
            containerInput="xl:w-[380px] lg:w-[370px] md:w-[330px]"
          />
        </div>
      </Form>
    </div>
  );
};
