import { useState } from "react";
import { ModalConfirmationTemplate, ModalTemplate } from "../common/Modals";
import { Form } from "../common/Form";
import { Input } from "../common/ElementsForm/Input";
import { useForm } from "react-hook-form";
import {
  postCancelSuscriptionURL,
  postUpdateEmailURL,
} from "../../utils/ConstantsURLs";
import { dataFAQsNotiwero } from "../../utils/Data";
import { useNavigate } from "react-router-dom";

export const FAQsNotiwero = () => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [typeModal, setTypeModal] = useState("");

  return (
    <div className="min-h-screen xl:px-28 lg:px-24 px-6 sm:py-32 py-20 flex flex-col items-center justify-center w-full space-y-16 ">
      <div>
        <p className="font-normal text-sm text-colorGreen font-noto text-center">
          ¿Por qué suscribirte?
        </p>
        <h4 className="lg:text-4xl sm:text-3xl text-2xl font-medium lg:max-w-3xl text-center">
          Preguntas frecuentes
        </h4>
      </div>

      <div className="flex lg:flex-row flex-col xl:space-y-0 space-y-10 items-center xl:justify-between justify-center lg:space-x-11 w-full">
        <div className="md:w-[491px] sm:w-[391px] w-[291px] ">
          <img
            src="/images/notiwero/FAQs.svg"
            alt="Imagen FAQs"
            className="w-full object-cover"
          />
        </div>

        <div className="space-y-10 xl:w-1/2 md:w-2/3 w-full">
          {dataFAQsNotiwero.map((item, index) => (
            <div className="space-y-4" key={index}>
              <p className="font-noto text-colorGreen font-semibold sm:text-[22px] text-[18px]">
                {item.question}
              </p>

              <div>
                <p className="text-base font-work sm:text-base text-[16px]">
                  {item.answer}{" "}
                  <span className="text-[#0064E5] font-semibold">
                    {item.email}
                  </span>
                </p>

                <div>
                  {item.button?.map((button, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        setShowModalConfirm(true);
                        setTypeModal(button.type);
                      }}
                      className="text-[#0064E5] underline font-work sm:text-base text-[16px] h-auto"
                    >
                      {button.text}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ModalAnswer
        isOpen={showModalConfirm}
        onClose={() => setShowModalConfirm(!showModalConfirm)}
        onConfirm={() => {
          setShowModalConfirm(!showModalConfirm);
          setShowModalEmail(!showModalEmail);
        }}
        typeModal={typeModal}
      />

      <ModalEmail
        isOpen={showModalEmail}
        onClose={() => {
          setShowModalEmail(!showModalEmail);
          setShowModalConfirm(!showModalConfirm);
        }}
        typeModal={typeModal}
      />
    </div>
  );
};

const ModalAnswer = ({ isOpen, onClose, onConfirm, typeModal }) => {
  return (
    <ModalTemplate isOpen={isOpen} onClose={onClose}>
      <h2
        className={`sm:text-2xl text-xl text-colorGreen font-semibold text-center`}
      >
        ¿Estás seguro que quieres{" "}
        {typeModal === "actualizar"
          ? " actualizar tu correo?"
          : "cancelar tu suscripción?"}
      </h2>
      <div className="flex sm:flex-row flex-col sm:space-x-6 sm:space-y-0 space-y-4 mt-16 ">
        <button onClick={onClose} className="btn-green w-[190px]">
          No, regresar
        </button>
        <button onClick={onConfirm} className="btn-line-red w-[190px]">
          Si, {typeModal}
        </button>
      </div>
    </ModalTemplate>
  );
};

const ModalEmail = ({ isOpen, onClose, typeModal }) => {
  const [serverError, setServerError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModalSucces, setShowModalSuccess] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const handleEndpoint = (email) => {
    return typeModal === "actualizar"
      ? postUpdateEmailURL(email)
      : postCancelSuscriptionURL(email);
  };

  const dataForm = async ({ email }) => {
    try {
      setLoading(true);

      const response = await fetch(handleEndpoint(email), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        reset();
        setServerError(null);
        setLoading(false);
        setShowModalSuccess(true);
        navigate(typeModal === "actualizar" ? `/notiwero/actualizar-email/${data.email}` : `/notiwero/cancelar-suscripcion/${data.email}`);
        sessionStorage.setItem(
          typeModal === "actualizar" ? "emailUpdate" : "emailCancel",
          data.email
        );
      } else {
        setServerError(data.error);
        setLoading(false);
        setShowModalSuccess(false);
      }
    } catch (error) {
      setServerError("Ocurrió un error, por favor intenta de nuevo.");
      setShowModalSuccess(false);
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalTemplate isOpen={isOpen} onClose={onClose}>
        <div className="space-y-6">
          <h2 className="sm:text-2xl text-xl text-colorGreen font-semibold text-center">
            Ingresa tu correo donde recibes el Newsletter
          </h2>
          <p className="text-sm text-center">
            Por favor, ingresa tu correo para verificar que eres el propietario
            del email y completar el proceso de{" "}
            <span className="font-semibold text-colorGreen uppercase">
              {typeModal === "actualizar" ? "actualización" : "cancelación"}.
            </span>
          </p>
        </div>
        <div className="mt-6 ">
          <Form
            onSubmit={handleSubmit(dataForm)}
            textButton="Entendido"
            classButton="bg-colorGreen sm:w-[255px] w-[220px] text-white"
            containerButton="flex w-full justify-center items-center"
            styleIsValid="hover:border-2 hover:border-colorGreen hover:bg-transparent hover:text-colorGreen"
            isValid={isValid}
            loading={loading}
          >
            <Input
              name="email"
              type="email"
              placeholder="Escribe aquí tu correo electrónico"
              textLabel="Correo electrónico"
              register={register}
              errors={{ ...errors, serverError }}
              classInput="input-line-green text-colorGray"
              classLabel="text-colorGreen hidden"
              containerInput="xl:w-[380px] lg:w-[370px] md:w-[330px]"
            />
          </Form>
        </div>
      </ModalTemplate>

      <ModalConfirmationTemplate
        isOpen={showModalSucces}
        onClose={() => setShowModalSuccess(false)}
      >
        <h2 className="text-2xl text-colorGreen font-semibold text-center">
          Correo enviado
        </h2>
        <p className="text-center text-base">
          Enviamos un correo de confirmacion para asegurarnos que tu hiciste la
          peticion, por favor revisa tu <strong className="text-colorGreen">bandeja de entrada o spam</strong>.
        </p>
      </ModalConfirmationTemplate>
    </>
  );
};
