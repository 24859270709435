import { motion } from "framer-motion";

export const Banner = () => {
  return (
    <>
      <div className="bg-colorDarkBlue min-h-screen xl:px-28 lg:px-20 sm:px-12 px-6 flex lg:flex-row flex-col items-center justify-center lg:space-x-20 lg:space-y-0 space-y-10">
        <div className="xl:max-w-md space-y-3 lg:text-left text-center">
          <p className="text-white text-sm font-noto">WTF con el NotiWero</p>
          <h4 className="text-white sm:text-[40px] sm:leading-[54px] text-2xl">
            ¿Te cuesta estar al día con las últimas tendencias de negocios y
            emprendimiento?
          </h4>
        </div>

        <div className="space-y-10 2xl:max-w-xl lg:max-w-md flex flex-col lg:items-start items-center justify-center">
          <div className="space-y-6 ">
            <p className="text-colorYellow text-lg">
              ¡Tranquilo, para eso está El NotiWero!
            </p>
            <p className="text-white text-lg leading-6">
              Cada día recibirás en tus manos información relevante, cruda y
              directa que necesitas para tomar{" "}
              <span className="text-colorYellow">
                decisiones inteligentes en tu negocio
              </span>{" "}
              y estar siempre un paso adelante. ¿Por qué perderse en un mar de
              información cuando puedes tener lo relevante en un solo lugar?
            </p>
          </div>
          <div>
            <motion.a
              href="#suscripcion"
              whileTap={{ scale: 0.9 }}
              className="btn-yellow w-[140px]"
            >
              Suscríbete{" "}
            </motion.a>
          </div>
        </div>
      </div>
    </>
  );
};

// export const Banner = () => {
//   const [show, setShow] = useState(true);

//   const donate = () => {
//     const url = "https://donate.stripe.com/bIY5m4gAn6hSfBu289"
//     window.open(url, "_blank", "noopener,noreferrer");
//   }

//   return (
//     <>
//       <motion.section
//         initial={{ opacity: 0, scale: 0.5 }}
//       animate={{ opacity: 1, scale: 1 }}
//         transition={{
//           duration: 0.4,
//           delay: 0.1,
//           ease: [0, 0.71, 0.2, 1.01]
//         }}
//         className="fixed bottom-0 w-full z-10 "
//       >
//         <div className="relative">
//           <motion.button
//             onClick={() => setShow(!show)}
//             whileTap={{ scale: 0.85 }}
//             className=" absolute -bottom-1 md:right-16 right-3 bg-colorGreen rounded-t-[4px] rounded-b-none p-4"
//           >
//             <motion.i
//               animate={{
//                 rotate: !show ? 0 : -45,
//                 duration: 0.5,
//               }}
//               className="fas fa-plus text-colorYellow text-xl "
//             />
//           </motion.button>
//         </div>

//         <motion.div
//           animate={{
//             height: show ? "auto" : 0,
//             opacity: show ? 1 : 0,
//             overflow: show ? "visible" : "hidden",
//           }}
//           className="bg-colorGreen "
//         >
//           <div className="w-full py-10 xl:px-28 lg:px-24 px-6 flex lg:flex-row flex-col justify-between items-center lg:space-y-0 space-y-8">
//             <div className="lg:w-[106px] w-[88px]">
//               <Logos fill="#F3FF03" />
//             </div>
//             <div className="xl:max-w-xl max-w-md font-noto font-light space-y-8 text-center">
//               <h1 className="text-colorYellow text-4xl">
//                 Sé que te gusta lo que ves...
//               </h1>
//               <p className="text-colorSand">
//                 Con tu valiosa ayuda podemos hacer que el NotiWero crezca y{" "}
//                 <span className="text-colorYellow">
//                   llegue a más emprendeduros
//                 </span>{" "}
//                 como tú
//               </p>
//             </div>
//             <div>
//               <motion.button whileTap={{ scale: 0.90 }} onClick={() => donate()} className="btn-white w-[241px]">Que crezca el NotiWero</motion.button>
//             </div>
//           </div>
//         </motion.div>
//       </motion.section>
//     </>
//   );
// };
