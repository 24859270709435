// import { motion } from "framer-motion";
// import { Logos } from "../../../../assets/Logos";
import { CommingSonn } from "../../../../pages/public/CommingSoon";
import SEO from "../../../../seo/SEO";
// import { FormUser } from "./FormUser";
// import { Schudle } from "./Schudle";
// import { FormPay } from "./FormPay";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { dataStepper } from "../../../../utils/Data";

export const ProcesPay = () => {
  // const navigate = useNavigate();
  // const [currentStep, setCurrentStep] = useState(0);

  // const handleNext = () => {
  //   setCurrentStep((prevStep) => prevStep + 1);
  // };

  // const handlePrevious = () => {
  //   setCurrentStep((prevStep) => prevStep - 1);
  // };

  return (
    <>
      <SEO
        title="Suscripción Coaching"
        description="Descubre herramientas y consejos de expertos en coaching uno a uno con El Wero Bisnero. Únete a nuestra comunidad y potencia tu éxito personal y profesional."
      />
      <CommingSonn title="Suscripción Coaching" />
      {/* <section className="min-h-screen bg-colorSand1 lg:px-20 px-6 py-8">
        <div className="flex ">
          <a href="/inicio" rel="noreferrer noopener">
            <motion.div
              whileTap={{ scale: 0.9 }}
              className="md:w-[101px] w-[80px]"
            >
              <Logos fill="#053F2E" />
            </motion.div>
          </a>
        </div>

        <div className="sm:pt-0 pt-10">
          <Stepper currentStep={currentStep} />
          <div>
            {currentStep === 0 && <FormUser onNext={handleNext} />}
            {currentStep === 1 && (
              <Schudle onNext={handleNext} onPrevious={handlePrevious} />
            )}
            {currentStep === 2 && <FormPay onPrevious={handlePrevious} />}
          </div>
        </div>
      </section> */}
    </>
  );
};

// const Stepper = ({ currentStep }) => {
//   return (
//     <div className="flex justify-center items-center ">
//       {dataStepper.map((item, index) => (
//         <div className="flex flex-row justify-center items-center" key={index}>
//           <div className="flex flex-col items-center space-y-2">
//             <div
//               className={`sm:h-12 sm:w-12 h-10 w-10 rounded-full flex items-center justify-center ${
//                 currentStep >= index
//                   ? "bg-colorGreen border-2 border-colorGray2"
//                   : "bg-[#C5C6C8]"
//               }`}
//             >
//               <i className="sm:w-6 w-5  ">{item.icon}</i>
//             </div>
//             <p
//               className={`text-xs font-medium font-montserrat ${
//                 currentStep >= index ? "text-colorGray" : "text-[#C5C6C8]"
//               }`}
//             >
//               {item.title}
//             </p>
//           </div>
//           {index < dataStepper.length - 1 && (
//             <div
//               className={`w-11 h-[2px] mx-3 mb-6 flex justify-center ${
//                 currentStep >= index ? "bg-colorGreen" : "bg-[#C5C6C8]"
//               }`}
//             />
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };
