export const AddonsCoaching = () => {
  return (
    <>
      <section className="space-y-14 ">
        <h4 className="text-colorYellow">Entregables add-on’s</h4>

        <div className="sm:space-y-14 space-y-10">
          <ul className="list-disc lg:ml-56 flex lg:flex-row flex-col lg:justify-between items-center lg:space-y-0 space-y-10 md:px-20 sm:px-10 px-4 ">
            <li className="text-colorYellow lg:max-w-5xl">
              <span className="text-colorYellow">
                Análisis de Mercado Personalizado:
              </span>{" "}
              <span className="text-colorSand">
                Un informe sobre tu nicho de mercado, incluyendo análisis de
                competencia, identificación de tu público objetivo y
                recomendaciones de posicionamiento.
              </span>
            </li>
            <li className="text-colorYellow lg:max-w-6xl">
              <span className="text-colorYellow">
                Resumen de Sesiones y Consejos Clave:
              </span>{" "}
              <span className="text-colorSand">
                Un compendio de los puntos más importantes tratados en las
                sesiones, incluyendo consejos prácticos y estratégicos
                personalizados.
              </span>
            </li>
          </ul>

          <ul className="list-disc flex lg:flex-row flex-col lg:justify-between items-center lg:space-y-0 space-y-10 lg:px-0 md:px-20 sm:px-10 px-4  ">
            <li className="text-colorYellow lg:max-w-xl">
              <span className="text-colorYellow">
                Estrategia de Marketing y Ventas:
              </span>{" "}
              <span className="text-colorSand">
                Un plan específico para promocionar y vender tu producto o
                servicio, incluyendo estrategias digitales y tradicionales
                adaptadas a tu mercado y audiencia.
              </span>
            </li>
            <li className="text-colorYellow lg:max-w-2xl">
              <span className="text-colorYellow">
                Recomendaciones de Tecnología y Herramientas:
              </span>{" "}
              <span className="text-colorSand">
                Una lista de herramientas y tecnologías recomendadas para
                optimizar la gestión y operación de tu negocio.
              </span>
            </li>

            <li className="text-colorYellow lg:max-w-xl">
              <span className="text-colorYellow">Plan Financiero Inicial:</span>{" "}
              <span className="text-colorSand">
                Un plan financiero que incluye presupuestos iniciales,
                proyecciones de flujo de caja y estrategias de financiamiento.
              </span>
            </li>
          </ul>

          <ul className="list-disc lg:ml-80 flex lg:flex-row flex-col lg:justify-between items-center lg:space-y-0 space-y-10 md:px-20 sm:px-10 px-4 ">
            <li className="text-colorYellow lg:max-w-4xl">
              <span className="text-colorYellow">
                Guía de Branding y Comunicación de Marca:
              </span>{" "}
              <span className="text-colorSand">
                Un conjunto de directrices para el desarrollo de tu marca,
                incluyendo tono de voz, identidad visual y estrategias de
                comunicación efectivas.
              </span>
            </li>
            <li className="text-colorYellow lg:max-w-lg">
              <span className="text-colorYellow">
                Red de Contactos y Recomendaciones:
              </span>{" "}
              <span className="text-colorSand">
                Acceso a una red de contactos relevantes para tu negocio,
                incluyendo posibles colaboradores, proveedores o mentores.
              </span>
            </li>
            <li className="text-colorYellow lg:max-w-xl">
              <span className="text-colorYellow">
                Seguimiento Post-Consultoría:
              </span>{" "}
              <span className="text-colorSand">
                Una sesión de seguimiento después de completar el paquete para
                evaluar el progreso y ajustar estrategias si es necesario.
              </span>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
