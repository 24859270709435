import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "../common/Form";
import { Input } from "../common/ElementsForm/Input";
import { motion } from "framer-motion";
import { useGetProducts } from "../../hooks/useFetch/useGetProducts";
import { usePostCreateSession } from "../../hooks/useFetch/usePostCreateSession";

export const HistoryHome = () => {
  const [show, setShow] = useState(false);
  const { products } = useGetProducts();
  const selectedProduct = products && products.length > 0 ? products[0] : null;
  const priceId = selectedProduct ? selectedProduct.prices[0].id : null;

  return (
    <>
      <section className="flex flex-col md:space-y-32 sm:space-y-28 space-y-20">
        <div>
          <h3 className="text-colorYellow font-noto mb-5 sm:text-start text-center">
            Notiwero
          </h3>
          <InputNotiwero priceId={priceId} />
          <a
            href="/notiwero"
            className=" text-colorYellow underline w-fit text-xs font-normal h-0 mt-9"
          >
            Sobre el Notiwero
          </a>
        </div>
        <div className="space-y-10">
          <div className="flex justify-center">
            <a
              href="/el-wero"
              className="btn-yellow w-[250px] sm:h-16 uppercase"
            >
              Mi historia
            </a>
          </div>

          <div className="space-y-5">
            <motion.div
              animate={{
                height: show ? "auto" : "130px",
                overflow: show ? "visible" : "hidden",
              }}
              className={` relative`}
            >
              <p className="text-colorSand md:text-lg text-sm">
                Crecí en Ciudad de México, desde temprano aprendí a navegar en
                el caos creativo del mundo empresarial y bisnero de mi México
                mágico. Cuento con una formación académica en emprendimiento e
                innovación por Deusto y Cambridge y otros estudios en impuestos
                y negocios. Esto me permitió asesorar a CEOs y emprendedores
                transformando ideas en planes de negocio exitosos. Colaboré
                durante algunos años en una empresa pionera en tecnologías
                inmersivas. Aquí implementé soluciones digitales-interactivas
                que posicionaron a México como un país vanguardista en campañas
                de turismo internacional. Soy parte de una empresa pionera en
                big data en México, líder en el procesamiento de noticias en
                tiempo real. Mi experiencia aquí me ha brindado una comprensión
                profunda de la importancia de la información rápida y valiosa en
                el mundo de los negocios, habilidad que ahora transmito a la
                banda.
              </p>
              {!show && (
                <div className="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-t from-colorDarkBlue to-transparent pointer-events-none"></div>
              )}
            </motion.div>
            <button
              onClick={() => setShow(!show)}
              className="text-colorYellow text-sm "
            >
              {show ? "Mostrar menos" : "Mostrar más"}{" "}
              <motion.i
                animate={{
                  rotate: show ? 0 : -180,
                }}
                className="fas fa-angle-up text-base ml-2"
              />
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export const InputNotiwero = ({ priceId }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm({ mode: "onChange" });
  const { products } = useGetProducts();
  const product = products.find((p) => p.name === "Notiwero Plan Mensual");
  const { id } = product?.prices[0] || {};
  const { sendData, loading, serverError } = usePostCreateSession();

  return (
    <Form
      onSubmit={handleSubmit((data) => sendData(data, reset, id))}
      classForm="flex sm:flex-row flex-col  sm:space-x-5 sm:space-y-0 space-y-10"
      textButton="Suscribirse"
      classButton="btn-yellow sm:w-[180px] w-[155px] text-colorDarkBlue"
      containerButton="flex sm:justify-start justify-center"
      styleIsValid="hover:border-2 hover:border-colorGreen hover:bg-transparent hover:text-colorGreen"
      isValid={isValid}
      loading={loading}
    >
      <Input
        name="userEmail"
        type="email"
        placeholder="Escribe aquí tu correo electrónico"
        textLabel="Correo electrónico"
        register={register}
        errors={{ ...errors, serverError }}
        classInput="input-line-yellow text-colorSand w-full"
        classLabel="text-colorYellow hidden"
        containerInput=""
      />
    </Form>
  );
};
