import { Logos } from "../../assets/Logos";

export const AboutHero = () => {
  return (
    <section className="min-h-screen flex flex-col items-center justify-center bg-about relative">
        <div className="z-0">
        <h2 className="xl:text-[96px] lg:text-[70px] text-[50px] font-light text-colorSand mb-5">
          Aquí
        </h2>
        <div className="xl:ml-24 lg:ml-14">
          <div className="xl:w-[397px] lg:w-[297px] w-[260px]">
            <Logos fill="#F3FF03" />
          </div>
        </div>
        </div>
      </section>
  );
};