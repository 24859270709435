import { Logos } from "../../assets/Logos";
import { motion } from "framer-motion";

export default function Intro() {
  return (
    <div className="flex items-center justify-center min-h-screen p-6 bg-intro relative">
      <div className="bg-black/80 absolute inset-0"></div>
      <div className=" container h-[650px] relative flex flex-col items-center ">
        <div className=" absolute xl:left-48 xl:top-28 lg:top-40 md:top-48 top-36 lg:left-20">
          <h1 className="text-colorYellow font-brushaff xl:text-[96px] lg:text-[76px] md:text-[50px] sm:text-[40px] text-[35px]">
            No esperes oportunidades...
          </h1>
        </div>
        <div className="absolute xl:bottom-28 xl:right-48 lg:right-20 lg:bottom-40 md:bottom-48 bottom-[296px]">
          <h1 className="text-colorYellow font-kaushan xl:text-[284px] lg:text-[180px] md:text-[150px] sm:text-[128px] text-[110px] ">
            créalas
          </h1>
          <img
            src="images/app/shadow_line.png"
            alt="Shadow"
            className="xl:w-[461px] lg:w-[320px] md:w-[220px] xl:mt-11 xl:ml-14 md:flex hidden"
          />
        </div>

        <div className="absolute lg:bottom-0 xl:left-44 lg:left-20 left-0">
          <div className="sm:w-36 w-[108px]">
            <Logos fill="#F3FF03" />
          </div>
          <p className="text-colorYellow text-base">
            Tu cómplice en el emprendimiento
          </p>
        </div>

        <motion.div
          whileTap={{ scale: 0.85 }}
          className="bg-colorSand md:w-[142px] md:h-[142px] w-[108px] h-[108px] rounded-full flex items-center justify-center absolute xl:right-72 sm:right-36 md:bottom-0 bottom-24 transition-colors duration-300 hover:bg-transparent hover:border-2 hover:border-colorYellow hover:text-colorSand"
        >
          <a
            href="/inicio"
            className="md:text-lg text-[15px] font-semibold text-center"
          >
            QUIERO EMPRENDER
          </a>
        </motion.div>
      </div>
    </div>
  );
}
