export const EWBSesiones = () => {
  return (
    <>
      <section className="space-y-8">
        <div>
          <h4>Sesiones impartidas por</h4>
          <div className="relative">
            <h4 className="font-semibold text-colorGreen font-work">El Wero</h4>
            <img
              src="/images/app/shadow_line.png"
              alt="Sombra amarilla"
              className="absolute lg:w-[130px] w-20 lg:left-5 lg:top-10 left-4 top-8 rotate-[5deg]"
              draggable="false"
            />
          </div>
        </div>

        <div className="flex lg:flex-row flex-col justify-between lg:space-x-10 lg:space-y-0 space-y-10 ">
          <div>
            <div className="space-y-5">
              <p className="text-lg">
                Cuento con una formación académica en{" "}
                <span className="text-colorGreen font-medium">
                  emprendimiento e innovación
                </span>{" "}
                {""}
                por Deusto y Cambridge y otros estudios en impuestos y negocios.
                Esto me permitió asesorar a CEOs y emprendedores transformando
                ideas en planes de negocio exitosos.
              </p>

              <p className="text-lg">
                Colaboré durante algunos años en una empresa pionera en
                tecnologías inmersivas. Aquí implementé soluciones
                digitales-interactivas que{" "}
                <span className="text-colorGreen font-medium">
                  posicionaron a México como un país vanguardista
                </span>{" "}
                en campañas de turismo internacional, capacitación con realidad
                virtual y otras tecnologías de competencias laborales.
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center space-y-10 xl:block lg:hidden">
            <div className="sm:w-[330px]">
              <img
                src="/images/coaching/sesiones.png"
                alt="Rodo brazos cruzados"
                className="object-cover w-full"
              />
            </div>
            <a href="/coaching/coaching-uno-a-uno/cuentame-de-ti" className="btn-green w-[292px]">
              Quiero empezar mi negocio
            </a>
          </div>

          <div className="space-y-10">
            <p className="text-lg">
              Soy parte de una empresa pionera en big data en México, líder en
              el procesamiento de noticias en tiempo real. Mi experiencia aquí
              me ha brindado una comprensión profunda de{" "}
              <span className="text-colorGreen font-medium">
                la importancia de la información
              </span>{" "}
              rápida y valiosa en el mundo de los negocios, habilidad que ahora
              transmito a la banda.
            </p>

            <h1 className="text-3xl">
              "<span className="text-colorGreen font-medium">Emprende</span> con
              astucia,{" "}
              <span className="text-colorGreen font-medium">ejecuta</span> con
              acción y{" "}
              <span className="text-colorGreen font-medium">triunfa</span> con
              actitud".
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};
