import SEO from "../../seo/SEO";
import { CommingSonn } from "./CommingSoon";

export default function Store() {
    return (
        <>
        <SEO title="Tienda" description="Explora nuestro espacio único lleno de productos innovadores y experiencias excepcionales. ¡Sigue explorando, sitio en construcción!" />
        <CommingSonn title="Tienda" />
        </>
    )
}