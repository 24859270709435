import { Logos } from "../../assets/Logos";

import { motion } from "framer-motion";

export default function Error404() {
  return (
    <section>
      <div className="bg-notiwero flex flex-col justify-center items-center h-screen relative bg-colorSand1 ">
        <div className="z-10 flex flex-col items-center">
          <h2 className="lg:text-6xl sm:text-5xl text-4xl text-colorGreen font-noto font-medium ">
            ERROR
          </h2>
          <h1 className="text-colorGreen font-noto font-bold lg:text-[200px] sm:text-[180px] text-8xl text-center">
            404
          </h1>
          <h3 className="lg:text-2xl sm:text-2xl text-lg font-medium font-work sm:mb-20 mb-6 text-center">
            No se encontró la página.
          </h3>

          <motion.a
            whileTap={{ scale: 0.9 }}
            href="/"
            className="btn-green w-52"
          >
            Inicio
          </motion.a>
        </div>

        <div className="absolute left-7 top-7 ">
          <div className="sm:w-[120px] w-[80px]">
            <Logos fill="#053f2e" />
          </div>
        </div>
      </div>
    </section>
  );
}
