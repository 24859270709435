export const Logos = ({ fill}) => {
  return (
    <svg
      viewBox="0 0 858 482"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M594.7 0.100037C578.1 0.100037 565.5 4.50005 556.9 13.3C548.3 22.1 544 35.2 544 52.6V148.5C544 166.5 548.3 180.4 556.9 190.3C565.5 200.2 578.1 205.2 594.7 205.2C611.3 205.2 623.9 200.3 632.5 190.4C641.1 180.6 645.4 166.6 645.4 148.4V52.5C645.4 35.2 641.1 22.1 632.5 13.2C623.9 4.50001 611.3 0.100037 594.7 0.100037ZM604 150.3C604 155.9 603.4 160.3 602.1 163.4C600.8 166.5 598.4 168.1 594.7 168.1C590.9 168.1 588.2 166.6 586.8 163.6C585.4 160.6 584.6 156.3 584.6 150.5V52.3C584.6 42.1 588 37.1 594.9 37.1C598.6 37.1 601 38.5 602.2 41.2C603.4 43.9 604 47.5 604 51.9V150.3Z"
        fill={fill}
      />
      <path
        d="M226.1 129.3L215.7 0H179.7L168.9 129.3L158.2 0H120.4L141.1 205H188.3L197.5 106.2L207.2 205H253.5L274.6 0H236.4L226.1 129.3Z"
        fill={fill}
      />
      <path
        d="M334.4 95.7H411.8V57H334.4V39.6H411.9V0H292.6V153H411.8V113H334.4V95.7Z"
        fill={fill}
      />
      <path
        d="M195.3 327.9C208.9 324.1 215.7 312.4 215.7 292.8V283.7C215.7 267.1 212.4 255.2 205.9 247.8C199.3 240.5 188.8 236.8 174.3 236.8H120.4V442.1H174.3C190.8 442.1 202.2 436.5 208.5 425.2C214.8 414 218 397.4 218 375.6C218 362.2 216.3 351.5 212.9 343.3C209.5 335.3 203.6 330.1 195.3 327.9ZM159.2 268.4H167.5C173.2 268.4 176.1 271.7 176.1 278.4V299C176.1 303.8 175.5 307.5 174.2 310.1C173 312.7 170.7 314 167.3 314H159.2V268.4ZM178.7 390.7C178.7 400.9 173.5 406 163.2 406L159.3 405.8V346.3H166.7C171.2 346.3 174.3 347.9 176.1 351.1C177.9 354.3 178.8 359 178.8 365.2V390.7H178.7Z"
        fill={fill}
      />
      <path d="M274.7 236.8H235V442.1H274.7V236.8Z" fill={fill} />
      <path
        d="M488.5 335L470.2 236.8H429.1V442.1H468.1V339.3L488.1 442.1H527V236.8H488.5V335Z"
        fill={fill}
      />
      <path
        d="M586 355.1H626.6V316.4H586V276.5H626.4V236.8H544.2V442.1H626.6V402.2H586V355.1Z"
        fill={fill}
      />
      <path
        d="M734.7 321.4C737.7 314.9 739.1 305.8 739.1 294.1C739.1 281.4 738.2 270.9 736.3 262.7C734.4 254.5 730.8 248.1 725.4 243.6C720 239.1 715.1 236.8 705.3 236.8H643.6V442.1H684.3V348.4C690.1 348.4 694 349.2 696.2 350.8C698.4 352.4 699.5 356.1 699.5 361.8V442.1H738.8L739.3 364.4C739.3 356 737.9 349.3 735.2 344.3C732.5 339.4 728.1 336.3 722 335C729.3 332.5 731.7 328 734.7 321.4ZM694.2 312.8H684.6V274.8H694C696.3 274.8 698.1 275.3 699.4 276.4C700.6 277.4 701.5 279.3 702 282C702.5 284.7 702.7 288.8 702.7 294.2C702.6 306.6 699.8 312.8 694.2 312.8Z"
        fill={fill}
      />
      <path
        d="M397.4 327.4C393.1 321.7 387.6 315.6 381 309.2L352.8 282.1C343.8 273.6 339.2 265.4 339.2 257.5C339.2 253.9 340.1 251.1 342 249C343.9 246.9 346.7 245.9 350.5 245.9C353.7 245.9 356.2 246.8 358 248.7C359.8 250.6 360.7 253.1 360.7 256.3L361 273H410.4C410.4 248.3 405.6 230.2 396 218.6C389.6 210.9 380.6 205.8 368.9 203.2V184.9H335.9V202.2C323.9 204.2 314.3 209.3 307.2 217.4C297.7 228.2 292.9 244 292.9 264.7C292.9 283.9 301 301.4 317.2 317.2L338.1 338.1C345.6 345.4 350.9 350.9 353.8 354.3C356.7 357.8 359.1 362.1 361 367.3C362.9 372.5 363.8 379 363.8 386.9C363.8 392.9 363 397.2 361.3 399.7C359.6 402.2 356.6 403.5 352.3 403.5C344.6 403.5 340.7 397.5 340.7 385.4V355.2H293V378.9C293 403.2 297.9 420.8 307.7 431.7C314.3 439.1 323.8 444 336 446.4V465.5H369V447C382.2 444.7 392.2 439 398.8 429.6C407.6 417.3 411.9 399.1 411.9 375.2C411.9 364.5 410.6 355.2 407.9 347.4C405.2 339.9 401.8 333.1 397.4 327.4Z"
        fill={fill}
      />
      <path
        d="M522.2 84.5C525.2 78 526.7 68.9 526.7 57.3C526.7 44.6 525.7 34.1 523.8 25.9C521.9 17.7 518.2 11.3 512.7 6.80005C507.2 2.30005 502.2 0 492.2 0H429.1V205.2H470.7V111.6C476.6 111.6 480.7 112.4 482.9 114C485.1 115.6 486.2 119.3 486.2 125V205.2H526.4L526.9 127.6C526.9 119.2 525.5 112.5 522.7 107.5C519.9 102.6 515.4 99.5 509.2 98.2C516.7 95.6 519.1 91 522.2 84.5ZM480.7 75.9H470.9V37.9H480.5C482.9 37.9 484.7 38.4 486 39.5C487.3 40.5 488.2 42.4 488.6 45.1C489.1 47.8 489.3 51.9 489.3 57.3C489.3 69.7001 486.5 75.9 480.7 75.9Z"
        fill={fill}
      />
      <path
        d="M55.5996 108.2V0H78.6996V89.8H102.4V108.2H55.5996Z"
        fill={fill}
      />
      <path
        d="M23.9004 62.3H46.7004V41.9H23.9004V20.9H46.5004V0H0.400391V108.2H46.7004V87.2H23.9004V62.3Z"
        fill={fill}
      />
      <path
        d="M806.9 237.5C790.3 237.5 777.7 241.9 769.1 250.7C760.5 259.5 756.2 272.6 756.2 290V385.9C756.2 403.9 760.5 417.8 769.1 427.7C777.7 437.6 790.3 442.6 806.9 442.6C823.5 442.6 836.1 437.7 844.7 427.8C853.3 418 857.6 404 857.6 385.8V289.9C857.6 272.6 853.3 259.5 844.7 250.6C836.1 241.9 823.5 237.5 806.9 237.5ZM816.3 387.7C816.3 393.3 815.7 397.7 814.4 400.8C813.1 403.9 810.7 405.5 807 405.5C803.2 405.5 800.5 404 799.1 401C797.7 398 796.9 393.7 796.9 387.9V289.7C796.9 279.5 800.3 274.5 807.2 274.5C810.9 274.5 813.3 275.9 814.5 278.6C815.7 281.3 816.3 284.9 816.3 289.3V387.7Z"
        fill={fill}
      />
    </svg>
  );
};
