import { useEffect } from "react";
import { useLocation } from "react-router-dom";


export const PixelManager = () => {
    const location = useLocation();
  
    return (
      <>
        {location.pathname === '/notiwero/suscripcion/notiwero-plan-mensual' && <InitPixels pixelId="478956885075490" />}
        {location.pathname.startsWith('/notiwero') && <InitPixels pixelId="752028920264904"/>}
        {location.pathname.startsWith('/') && <InitPixels pixelId="CS3D3U3C77UF26CUGOPG"/>}
      </>
    );
  };

  const InitPixels = ({ pixelId }) => {
    useEffect(() => {
      initFacebookPixel(pixelId);
      initTikTokPixel(pixelId);
    }, [pixelId]);
  
    return (
      <noscript>
        <img height="1" width="1" style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`} 
          alt=""
        />
      </noscript>
    );
  };


  export const initFacebookPixel = (pixelId) => {
    if (typeof window.fbq === 'undefined') {
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      window.fbq('init', pixelId);
    }
    window.fbq('track', 'PageView');
  };

  const initTikTokPixel = (pixelId) => {
    (function(w, d, t) {
      w.TiktokAnalyticsObject = t; 
      var ttq = (w[t] = w[t] || []);
      ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent", "revokeConsent", "grantConsent"];
      ttq.setAndDefer = function(t, e) {
        t[e] = function() {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      for (var i = 0; i < ttq.methods.length; i++) {
        ttq.setAndDefer(ttq, ttq.methods[i]);
      }
      ttq.instance = function(t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) {
          ttq.setAndDefer(e, ttq.methods[n]);
        }
        return e;
      };
      ttq.load = function(e, n) {
        var r = "https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i = ttq._i || {};
        ttq._i[e] = [];
        ttq._i[e]._u = r;
        ttq._t = ttq._t || {};
        ttq._t[e] = new Date();
        ttq._o = ttq._o || {};
        ttq._o[e] = n || {};
        n = document.createElement("script");
        n.type = "text/javascript";
        n.async = !0;
        n.src = r + "?sdkid=" + e + "&lib=" + t;
        e = document.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(n, e);
      };
      ttq.load(pixelId);
      ttq.page();
    })(window, document, 'ttq');
  };