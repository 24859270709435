import { dataGridsNotiwero } from "../../utils/Data";

export const Grids = () => {
  return (
    <>
      <div className="xl:px-28 lg:px-24 sm:px-14 px-6 md:py-36 sm:py-32 py-20 sm:space-y-20 space-y-10">
        <div className="text-center w-full flex flex-col items-center space-y-2">
          <p className="font-normal text-sm text-colorGreen font-noto">
            ¿Por qué suscribirte?
          </p>
          <h1 className="lg:text-4xl sm:text-3xl text-2xl font-medium lg:max-w-3xl ">
            La información que realmente importa peladita y a la boca
          </h1>
        </div>
        <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-x-16 sm:gap-x-12 md:gap-y-20 gap-y-12">
          {dataGridsNotiwero.map((item, index) => (
            <div key={index} className="">
              <h3 className="lg:text-2xl text-xl text-colorGreen font-noto font-bold mt-4 md:text-start text-center">
                {item.title}
              </h3>
              <p className="text-base mt-2">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
