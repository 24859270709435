export const NotiHero = ({ rute, text }) => {
  return (
    <>
      <div className=" w-full space-y-10">
        <h3>NotiWero</h3>
        <div className="flex xl:flex-row flex-col justify-between xl:items-center xl:space-y-0 space-y-8">
          <p className="xl:max-w-md">
            Tu{" "}
            <span className="text-colorGreen font-medium">
              dosis diaria de noticias
            </span>
            , sin rodeos ni aburrimiento. Información cruda, directa y con el
            toque único del Wero.
          </p>
          <div className="flex sm:flex-row flex-col sm:space-x-7 sm:space-y-0 space-y-5">
            <a href={rute} className="btn-line w-[255px]">
              {text}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
