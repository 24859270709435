export const VideoHome = () => {
  return (
    <section className="flex justify-center">
      <iframe
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/6Jxe2uQb7j8?si=4mvGGik03xA7gPWW" 
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          className="lg:max-w-4xl md:h-[500px] sm:h-80 h-64 rounded-xl"
        ></iframe>
    </section>
  );
};
