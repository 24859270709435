import { motion } from "framer-motion";
export const FormPay = ({ onPrevious }) => {
  // const handleNext = () => {
  //       onNext();
  //   };

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <div className="space-y-10 pt-10 ">
      <div className="flex flex-col items-start space-y-5">
        <div className="flex items-center">
          <motion.button
            whileTap={{ scale: 0.9 }}
            className="md:text-[40px] text-[28px]"
            onClick={() => handlePrevious()}
          >
            <i className="fas fa-angle-left text-colorGreen mr-3" />
          </motion.button>
          <span className="font-light tracking-[-1.5px] font-noto md:text-[40px] text-[28px]">
            Reserva tus asesorías
          </span>
        </div>
        <p className="text-lg md:pl-11">
          Por favor, selecciona una sesión por semana en los horarios
          disponibles.
        </p>
      </div>

      <div className="flex xl:flex-row flex-col xl:justify-between w-full lg:px-20 md:px-10 xl:space-x-20 xl:space-y-0 space-y-10">
        <div className="space-y-6 xl:w-[550px]">
          <p className="font-medium">Tu asesoría</p>

          <div className="space-y-9 border-b border-colorGreen pb-7">
            <div className="flex flex-row items-center justify-between">
              <div className="">
                <p className="text-[18px] font-medium">Fechas</p>
                <p className="text-[18px] font-normal">
                  01-30 de enero de 2024
                </p>
              </div>
              <a
                href="/coaching-uno-a-uno"
                rel="noreferrer noopener"
                className="underline"
              >
                Editar
              </a>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="">
                <p className="text-[18px] font-medium">Modalidad</p>
                <p className="text-[18px] font-normal">
                  Presencial - $55,000 $40,500
                </p>
              </div>
              <a
                href="/coaching-uno-a-uno"
                rel="noreferrer noopener"
                className="underline"
              >
                Editar
              </a>
            </div>
          </div>

          <div className="space-y-9">
            <p className="font-medium text-3xl text-colorGreen">
              ¡50% de descuento de salida a los primeros 3 emprendeduros!
            </p>

            <div className="space-y-4">
              <div>
                <p className="font-medium">
                  Todo esto obtendrás al contratar las asesorías:
                </p>
                <ul className="list-disc ml-9">
                  <li className="text-lg">Plan de Negocios Completo</li>
                  <li className="text-lg">Plan de Acción Estratégico</li>
                </ul>
              </div>

              <div>
                <div>
                  <p className="text-[18px] font-medium">Add-on’s</p>
                  <ul className="list-disc ml-9">
                    <li className="text-lg">
                      Análisis de Mercado Personalizado
                    </li>
                    <li className="text-lg">
                      Estrategia de Marketing y Ventas
                    </li>
                    <li className="text-lg">
                      Guía de Branding y Comunicación de Marca
                    </li>
                    <li className="text-lg">
                      Resumen de Sesiones y Consejos Clave
                    </li>
                    <li className="text-lg">Plan Financiero Inicial</li>
                    <li className="text-lg">
                      Red de Contactos y Recomendaciones
                    </li>
                    <li className="text-lg">Seguimiento Post-Consultoría</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:w-[428px]">
          <CheckoutForm />
        </div>
      </div>
    </div>
  );
};

const CheckoutForm = () => {
  return (
    <form className="border border-colorGreen rounded-2xl divide-y divide-colorGreen ">
      <div className="p-5">
        <p className="font-medium border-b border-colorGreen pb-5">
          Coaching uno a uno - 10 sesiones - Presencial
        </p>

        <div className=" border-b border-colorGreen py-5">
          <div className="flex justify-between">
            <p className="font-medium text-[18px]">Subtotal</p>
            <p className="font-normal text-[18px]">$55,000</p>
          </div>

          <div className="flex justify-between">
            <p className="font-medium text-[18px]">Descuento</p>
            <p className="font-normal text-[18px]">10%</p>
          </div>
        </div>

        <div className="flex justify-between py-5">
          <p className="font-medium text-[18px]">Total</p>
          <p className="font-medium text-[18px] text-[#000000]">$49,500</p>
        </div>
      </div>

      <div className="p-5 space-y-5">
        <Input
          name="email"
          type="text"
          placeholder="Dirección de correo eletrónico"
        />

        <Input
          name="email"
          type="text"
          placeholder="Nombre y Apellido"
        />
        <Input
          name="email"
          type="text"
          placeholder="Número de tarjeta"
        />
        <Input
          name="email"
          type="text"
          placeholder="CVC"
        />
        <Input
          name="email"
          type="text"
          placeholder="Código Postal"
        />

        <a href="/success-pay" className="btn-green w-full">
          Realizar pago
        </a>
      </div>
    </form>
  );
};

const Input = ({ placeholder, type, name }) => {
  return (
    <div className="">
      <input
        id={name}
        type={type}
        placeholder={placeholder}
        className="border border-colorGreen bg-transparent rounded-md w-full"
      />
    </div>
  );
};
