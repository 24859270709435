import React, { useEffect, useRef } from "react";
import Navbar from "../../components/common/Navbar";
import SEO from "../../seo/SEO";
import { HeroCoaching } from "./coachingUno/HeroCoaching";
import { BackgroundImg } from "./coachingUno/BackgronundImg";
import { BenefitsCoaching } from "./coachingUno/BenefitsCoaching";
import { SesionesCoaching } from "./coachingUno/SesionesCoaching";
import { DeliverablesCoaching } from "./coachingUno/DeliverablesCoaching";
import { EWBSesiones } from "./coachingUno/EWBSesiones";
import { PlanCoaching } from "./coachingUno/PlanCoaching";
import { AddonsCoaching } from "./coachingUno/AddonsCoaching";

export const CoachingUnoUno = () => {
  const containerRef = useRef(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      if (containerRef.current && scrollContainerRef.current) {
        const delta = event.deltaY || -event.detail;
        const speedFactor = 2;
        const scrollAmount = delta * speedFactor;
        containerRef.current.scrollLeft += scrollAmount;
        event.preventDefault();
      }
    };

    const containerElement = containerRef.current;

    if (containerElement) {
      containerElement.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <>
      <SEO
        title="Coaching Uno a Uno"
        description="Potencia tu desarrollo personal con sesiones de coaching personalizadas con El Wero Bisnero. Descubre tu máximo potencial y alcanza tus metas de manera efectiva y enfocada."
      />
      <Navbar
        color="#053F2E"
        bgColor="lg:bg-transparent bg-colorSand1"
        colorTxt="text-colorGray"
        colorIcon="#343434"
      />

      <div
        id="main"
        className="overflow-x-hidden min-h-screen"
        ref={containerRef}
      >
        <main
          ref={scrollContainerRef}
          className="horizontal-slide flex lg:flex-row flex-col lg:w-[900%] bg-colorSand1 min-h-screen"
        >
          <div className=" w-screen md:pt-36 pt-32 pb-20 xl:pl-28 lg:pl-24 px-6">
            <HeroCoaching />
          </div>

          <div className=" w-screen xl:pl-28 lg:pl-24 sm:py-0 lg:pt-0 md:pt-32 py-0">
            <BackgroundImg />
          </div>

          <div className=" md:pt-36 sm:pt-32 py-20 xl:px-28 lg:px-24 px-6 w-screen">
            <BenefitsCoaching />
          </div>

          <div className=" xl:px-28 lg:px-24 px-6 w-screen">
            <SesionesCoaching />
          </div>

          <div className="md:pt-36 sm:pt-32 pt-20 xl:px-28 lg:px-24 px-6 w-screen">
            <DeliverablesCoaching />
          </div> 
          
          <div className="md:pt-44 sm:pt-32 py-20 xl:px-28 lg:px-0 px-6 w-screen">
            <PlanCoaching />
          </div> 
          
          <div className="md:pt-36 sm:pt-32 py-20 xl:px-28 lg:px-10 px-6 lg:w-[200vw] w-screen bg-colorGreen">
            <AddonsCoaching/>
          </div> 

          <div className="md:pt-28 sm:pt-32 py-20 xl:px-28 lg:px-24 px-6 w-screen ">
            <EWBSesiones />
          </div>
        </main>
      </div>
    </>
  );
};