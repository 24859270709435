export const Hero = ({ rute, text }) => {
  return (
    <>
      <div className="bg-notiwero w-full space-y-4 min-h-screen flex flex-col justify-center items-center">
        <h3 className="xl:text-3xl lg:text-2xl text-xl  text-colorGreen">NotiWero</h3>
        <div className="flex flex-col xl:space-y-24 space-y-8 items-center">
          <p className="xl:max-w-6xl xl:text-[56px] xl:leading-[72px] lg:text-5xl sm:text-4xl text-2xl font-noto text-center md:px-20 sm:px-10 px-6">
            Tu {""}
            <span className="text-colorGreen font-medium">
              dosis diaria de noticias, {""}
            </span>
            sin rodeos ni aburrimiento. Información cruda, directa y con el
            toque único del Wero.
          </p>

          <div className="flex md:flex-row flex-col md:space-y-0 space-y-3 md:space-x-8">
            <a href="#suscripcion" className="btn-green w-[255px]">
              Suscribete
            </a>

            <a href={rute} className="btn-line w-[255px]">
              {text}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

// export const Hero = () => {
//   return (
//     <>
//       <div className="min-h-screen relative w-full flex flex-row items-center">
//         <div className=" absolute right-0 -z-10">
//           <img
//             src="/images/notiwero/bg.png"
//             alt="notiwero"
//             className=" object-cover w-[1022px]"
//           />
//         </div>
//         <div className="w-[645px]">
//           <img
//             src="/images/notiwero/hero.png"
//             alt="NotiWero"
//             className="w-full"
//           />
//         </div>

//         <div className=" max-w-md ml-20 space-y-48">
//           <div className="space-y-8">
//             <p className="">
//               Tu <span>dosis diaria de noticias</span>, sin rodeos ni
//               aburrimiento. Información cruda, directa y con el toque único del
//               Wero.
//             </p>

//             <button
//               onClick={() => sendMessage()}
//               className="btn-green w-[255px]"
//             >
//               <i className="fab fa-whatsapp mr-3" />
//               Recíbelo en WhatsApp
//             </button>
//           </div>

//           <div className="flex flex-col justify-center items-center w-fit space-y-2 text-colorGray">
//             <p className="text-sm">Scroll</p>
//             <a href="/" className="animate-bounce">
//               <i className="fas fa-angles-down text-4xl" />
//             </a>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };


