import { CommingSonn } from "../../pages/public/CommingSoon";
import SEO from "../../seo/SEO";

export const Courses = () => {
  return (
    <>
      <SEO
        title="Cursos"
        description="Desarrolla tus habilidades emprendedoras con los cursos de El Wero Bisnero. Accede a contenido educativo de alta calidad diseñado para impulsar tu crecimiento y liderazgo en el mundo empresarial."
      />
      <CommingSonn title="Cursos" />
    </>
  );
};
