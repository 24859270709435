import { DataSesionsCoaching } from "../../../utils/Data";
import { motion } from "framer-motion";

export const SesionesCoaching = () => {
  return (
    <>
      <section className="flex items-center justify-center min-h-screen">
        <div className="grid md:grid-cols-2 grid-cols-1 sm:gap-x-14 gap-y-10">
          {DataSesionsCoaching.map((sesion, index) => (
            <div
              key={index}
              className={`${sesion.bg} xl:p-10 sm:p-7 p-4 flex flex-col items-center xl:space-y-10 sm:space-y-5 space-y-4 rounded-2xl`}
            >
              <h4 className={`${sesion.colorTitle} `}>
                {sesion.title}
              </h4>
              <p className={`${sesion.colorText} lg:text-lg text-base`}>
                {sesion.description}
              </p>

              <motion.a
                whileTap={{ scale: 0.90 }}
                href="/coaching/coaching-uno-a-uno/cuentame-de-ti"
                className={`${sesion.btn} w-[214px]`}
              >
                {sesion.labelbtn}
              </motion.a>
              <p className={`${sesion.colorLabel} text-xs`}>{sesion.range}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
