import { DataTickerImage } from "../../utils/Data";
import Marquee from "react-fast-marquee";

export const TickerImage = () => {
  return (
    <>
      <section className="xl:mx-28 lg:mx-16 mx-7">
        <Marquee speed={50} loop={0}>
          <div className="flex space-x-6 mr-6">
            {DataTickerImage.map((item, index) => (
              <img
                key={index}
                src={item.src}
                alt={item.alt}
                className="md:w-[350px] w-[280px] h-96 object-cover rounded-lg"
              />
            ))}
          </div>
        </Marquee>
      </section>
    </>
  );
};