import { motion } from "framer-motion";
import { Logos } from "../../../assets/Logos";
import { useNavigate } from "react-router-dom";

export const SuccessSuscription = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-colorSand1 min-h-screen w-full flex flex-col items-center md:py-4 py-8 px-6 bg-success">
        <div className="md:w-[101px] w-[80px] md:mb-32 mb-20">
          <Logos fill="#053F2E" />
        </div>

        <div className="sm:w-[350px] w-[250px] mb-10">
          <img
            src="/images/notiwero/succes_noti.png"
            alt="Imagen newslatter"
            className="object-cover w-full"
          />
        </div>

        <div className="space-y-8 flex flex-col justify-center items-center w-full mb-16">
          <h4 className="md:text-4xl sm:text-3xl text-2xl font-bold text-center text-colorGreen">
            ¡Bienvenido al
            <br />
            Notiwero!
          </h4>

          <p className="md:text-base text-sm text-center">
            Tu pago se ha realizado con éxito.
          </p>

          <p className="md:text-base text-sm text-center">
            Lo recibirás de
            <strong className="text-colorGreen"> lunes a viernes </strong>
            en tu correo electrónico 
            <br />
            todos los días a las 10:30 a.m.
          </p>

          <p className="md:text-base text-sm text-center mt-5">
            Por favor, revisa tu{" "}
            <strong className="text-colorGreen">bandeja de entrada</strong> o{" "}
            <strong className="text-colorGreen">spam</strong>.
          </p>
        </div>

        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            navigate("/notiwero");
            sessionStorage.removeItem("customerEmail");
          }}
          className="btn-green w-[250px] rounded-lg text-[15px]"
        >
          Regresar al Wero Bisnero
        </motion.button>
      </div>
    </>
  );
};
