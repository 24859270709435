export const VideoFrame = () => {
  return (
    <>
      <section className="flex justify-center w-full md:pt-52 pt-20">
        <iframe
          src="https://www.youtube-nocookie.com/embed/V0_5NTSSiHU?si=RKng4XGiQigqbzET"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="md:w-[80%] w-[90%] lg:h-[637px] md:h-[60%]s sm:h-[349px] h-[249px] rounded-lg"
        />
      </section>
    </>
  );
};

