import { useState } from "react";
import { DataSectionGrids } from "../../../utils/Data";
import { motion } from "framer-motion";

export const GridsSections = () => {
  const [showStates, setShowStates] = useState({});

  const toggleShow = (index) => {
    setShowStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  return (
    <>
      <section className="w-full flex justify-center">
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-5 gap-y-10 max-w-5xl lg:ml-24 md:mx-0 mx-4">
          {DataSectionGrids.map((grid, index) => (
            <motion.div
              key={grid.id}
              className={`${grid.bg} p-4 relative rounded-md `}
              animate={{
                height: showStates[index] ? "500px" : "200px",
              }}
            >
              <div className="space-y-5">
                <h3
                  className={`font-light font-noto text-[40px] ${grid.colorText}`}
                >
                  {grid.title}
                </h3>
                <motion.div
                  animate={{
                    height: showStates[index] ? "auto" : 0,
                    opacity: showStates[index] ? 1 : 0,
                    overflow: showStates[index] ? "visible" : "hidden",
                  }}
                >
                  <p className={`text-xl ${grid.colorText1}`}>
                    {grid.description}
                  </p>
                </motion.div>

                <div className="absolute bottom-4 right-4">
                  <motion.button
                    onClick={() => toggleShow(index)}
                    whileTap={{ scale: 0.85 }}
                    className={`rounded-full ${grid.bgIcon} w-[46px]`}
                  >
                    <motion.i
                      animate={{
                        rotate: showStates[index] ? -45 : 0,
                        duration: 0.5,
                      }}
                      className={`fas fa-plus text-3xl ${grid.colorIcon}`}
                    />
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </>
  );
};
