import { DataBenefitsCoaching } from "../../../utils/Data";

export const BenefitsCoaching = () => {
  return (
    <>
      <section className="flex lg:flex-row flex-col justify-between items-center xl:space-x-20 lg:space-x-10 space-y-5">
        <div className=" space-y-10 ">
          <h4 className="xl:max-w-lg ">
            Estoy aquí para convertir tus sueños bisneros en{" "}
            <span className="font-semibold text-colorGreen">
              planes de acción concretos.
            </span>
          </h4>

          <div className="w-[452px] xl:flex hidden">
            <img
              src="/images/coaching/boligrafo.png"
              alt="Boligrafo"
              className="w-full object-cover"
            />
          </div>
        </div>

        <div className="lg:max-w-2xl md:space-y-10 space-y-5">
          <h4 className="">Beneficios de El Wero</h4>
          <ul className="list-disc sm:ml-10 ml-2 ">
            {DataBenefitsCoaching.map((benefit, index) => (
              <li key={index} className="text-colorGreen">
                <span className="font-medium">{benefit.title}: {" "}</span>
                <span className="text-colorGray">{benefit.description}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

//
//
//
//
