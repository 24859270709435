import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { NotiDescirption } from "./wtfnotiwero/NotiDescription";
import { GridsSections } from "./wtfnotiwero/GridsSections";
import SEO from "../../seo/SEO";
import { NotiHero } from "./wtfnotiwero/NotiHero";

export function WTFNotiwero() {
  return (
    <>
    <SEO
     title="WTF con el NotiWero"
     description="En esta seccion que pertenece al Notiwero de El Wero Bisnero, te explicamos que es el NotiWero y como puedes sacarle el mayor provecho."
   />
      <div className="bg-colorSand/50">
        <Navbar
          color="#053F2E"
          bgColor="bg-colorSand1"
          colorTxt="text-colorGray"
          colorIcon="#343434"
        />
        <div className="md:py-36 sm:py-32 py-28 xl:px-28 lg:px-24 px-6 sm:space-y-28 space-y-10">
          <NotiHero rute="/notiwero" text="NotiWero" />
          <NotiDescirption />
          <GridsSections />
        </div>
        <Footer/>
      </div>
    </>
  );
}
