import { useEffect, useState } from "react";
import { getPlansURL } from "../../utils/ConstantsURLs";

export const useGetProducts = () => {
    const [products, setProducts] = useState([]);
    
    useEffect(() => {
        const getProducts = async () => {
        try {
            const response = await fetch(getPlansURL(), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            });
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.log(error);
        }
        };
    
        getProducts();
    }, []);
    
    return { products };
}