export const PlanCoaching = () => {
  return (
    <>
      <section>
        <div className="flex md:flex-row flex-col-reverse items-center justify-between w-full md:space-x-16 lg:space-y-0 ">
          <div className="md:w-[488px] md:mt-0 mt-10">
            <img
              src="/images/coaching/socios.png"
              alt="Computadora"
              className="w-full object-cover rounded-2xl"
            />
          </div>
          <p className="xl:max-w-2xl lg:max-w-lg md:max-w-md">
            <span className="font-medium text-colorGreen">
            Plan de Acción Estratégico: 
            </span>{" "}
            Un cronograma paso a paso y plan de
            acción para implementar tu idea de negocio, incluyendo metas a
            corto, mediano y largo plazo con indicadores de éxito claros.
          </p>
        </div>
      </section>
    </>
  );
};
