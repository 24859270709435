import React, { useEffect } from 'react';

export default function SEO({ title, description }) {
  const defaultTitle = "El Wero Bisnero";
  const defaultDescription = "Descubre el mundo del emprendimiento con Wero Bisnero. Impulsa tu éxito con nuestro contenido personalizado y mantente informado con el notiwero.";

  useEffect(() => {
    document.title = title ? `${defaultTitle} | ${title}` : defaultTitle;
    return () => {
      document.title = defaultTitle;
    };
  }, [title]);

  return (
    <>
      <title>{title ? `${defaultTitle} | ${title}` : defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:title" content={title ? `${defaultTitle} | ${title}` : defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
    </>
  );
}
