import { AboutHero } from "../../components/about/AboutHero";
import { HistoryAbout } from "../../components/about/AboutHistory";
import { ContactAbout } from "../../components/about/ContactAbout";
import { TickerImage } from "../../components/about/TickerImage";
import { VideoFrame } from "../../components/about/VideoFrame";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";
import SEO from "../../seo/SEO";

export default function About() {
  return (
    <>
    <SEO
        title="Sobre mí"
        description="Conoce un poco mas de El Wero Bisnero, su historia, su trayectoria y su pasión por el coaching y el desarrollo personal."
      />

      <div className="bg-colorSand/50">
      <Navbar
        color="#F3FF03"
        bgColor="bg-colorDarkBlue"
        colorTxt="text-colorSand"
        colorIcon="#F2EFDD"
      />
        <AboutHero />
        <VideoFrame />
        <HistoryAbout />
        <TickerImage />
        <ContactAbout />
      <Footer />
      </div>
    </>
  );
}
