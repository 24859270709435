import { Logos } from "../../assets/Logos";
export const HeroHome = () => {
  return (
    <>
      <section className="sm:space-y-14 space-y-10">
        <div className="sm:space-y-14 space-y-10 flex flex-col justify-center items-center">
          <div className="sm:w-28 w-24">
            <Logos fill="#F3FF03" />
          </div>
          <img
            src="/images/home/gifhome.gif"
            alt="Gift"
            className="rounded-full md:w-96 md:h-96 sm:w-72 sm:h-72 w-52 h-52 object-cover"
          />
          <div className="">
            <p className="text-colorYellow font-brushaff italic font-light lg:text-5xl sm:text-4xl text-2xl text-center">
              Soy tu coach disruptivo en los{" "}
            </p>
            <div className="flex flex-col items-center">
              <p className="text-colorYellow font-kaushan lg:text-7xl sm:text-6xl text-5xl ">
                negocios
              </p>
              <img
                src="/images/home/brush.svg"
                alt="Imagen de pincel"
                className="object-cover lg:w-96 sm:w-64 w-52"
              />
            </div>
          </div>
        </div>

        {data.map((data, index) => (
          <div key={index} className="flex flex-col items-center md:space-y-14 space-y-10">
            <div className="flex sm:flex-row flex-col sm:space-x-10 sm:space-y-0 space-y-5">
              {data.primary.map((button, index) => (
                <a
                  key={index}
                  href={button.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={`btn-yellow sm:w-64 w-56 sm:h-11 h-10 rounded-lg flex items-center md:text-xl sm:text-lg text-base text-colorDarkBlue ${button.style}`}
                >
                  <i className={`${button.icon} mr-2`} />
                  {button.title}
                </a>
              ))}
            </div>
            <div className="flex space-x-10">
              {data.secondary.map((button, secondaryIndex) => (
                <a
                  key={secondaryIndex}
                  href={button.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="btn-yellow font-extrabold rounded-full sm:h-11 sm:w-11 w-10 h-10 flex items-center font-montserrat text-xl text-colorDarkBlue"
                >
                  <i className={button.icon} />
                </a>
              ))}
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

const data = [
  {
    primary: [
      {
        title: "Instagram",
        icon: "fab fa-instagram",
        link: "https://www.instagram.com/elwerobisnero/",
        style: "font-lobster font-normal",
      },
      {
        title: "TikTok",
        icon: "fab fa-tiktok",
        link: "https://www.tiktok.com/@elwerobisnero",
        style: "font-montserrat font-bold",
      },
    ],
    secondary: [
      {
        title: "Twitter",
        icon: "fab fa-x-twitter",
        link: "https://x.com/notiwero",
      },
      {
        title: "Facebook",
        icon: "fab fa-facebook-f",
        link: "https://www.facebook.com/elwerobisnero",
      },
      {
        title: "YouTube",
        icon: "fab fa-youtube",
        link: "https://www.youtube.com/@elwerobisnero",
      },
    ],
  },
];
