export const HistoryAbout = () => {
  return (
    <>
      <section className="xl:px-28 lg:px-24 px-6 md:py-36 sm:py-32 py-20 md:space-y-20 space-y-10">
        <div className="flex flex-col items-center w-full justify-center">
          <h2 className="md:text-7xl xs:text-[41px] text-[35px] relative">
              Esta es mi historia
            <img
              src="images/app/shadow_line.png"
              alt="Shadow line"
              className="absolute lg:-bottom-10 -bottom-2 right-0  md:w-[275px] w-[172px] rotate-[5deg]"
              />
          </h2>
        </div>

        <div className="space-y-6">
          <p>
            Crecí en Ciudad de México, desde temprano aprendí a navegar en el
            <span className="text-colorGreen font-medium">
              {" "}
              caos creativo del mundo empresarial
            </span>{" "}
            y bisnero de mi México mágico. Cuento con una formación académica en
            emprendimiento e innovación por Deusto y Cambridge y otros estudios
            en impuestos y negocios. Esto me permitió asesorar a CEOs y
            emprendedores transformando ideas en planes de negocio exitosos.
          </p>
          <p>
            Colaboré durante algunos años en una empresa pionera en tecnologías
            inmersivas. Aquí implementé soluciones digitales-interactivas que
            <span className="text-colorGreen font-medium">
              {" "}
              posicionaron a México como un país vanguardista
            </span>{" "}
            en campañas de turismo internacional.
          </p>
          <p>
            Soy parte de una empresa pionera en big data en México, líder en el
            procesamiento de noticias en tiempo real. Mi experiencia aquí me ha
            brindado una comprensión profunda de la{" "}
            <span className="text-colorGreen font-medium">
              {" "}
              importancia de la información rápida y valiosa
            </span>{" "}
            en el mundo de los negocios, habilidad que ahora transmito a la
            banda.
          </p>
        </div>
      </section>
    </>
  );
};
