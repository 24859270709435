import { Logos } from "../../../assets/Logos";
import { Form } from "../../common/Form";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Input, InputNumber } from "../../common/ElementsForm/Input";
import { postSendUpdateEmailURL } from "../../../utils/ConstantsURLs";
import { ModalConfirmationTemplate } from "../../common/Modals";

export const UpdateEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm({ mode: "onChange" });

  const [serverError, setServerError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const currentEmail = sessionStorage.getItem("emailUpdate");

  const dataForm = async (dataForm) => {
    setLoading(true);
    try {
      const response = await fetch(postSendUpdateEmailURL(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...dataForm, currentEmail }),
      });

      const data = await response.json();

      if (response.ok) {
        reset();
        setServerError(null);
        sessionStorage.removeItem("emailUpdate");
        setShowModal(true);
        setLoading(false);
      } else {
        setServerError(data.message);
        setShowModal(false);
        setLoading(false);
      }
    } catch (error) {
      setServerError("Ocurrió un error, por favor intenta de nuevo.");
      setShowModal(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-colorSand1 min-h-screen w-full flex flex-col items-center md:py-4 py-8 px-6">
      <div className="md:w-[101px] w-[80px] md:mb-20 mb-10">
        <Logos fill="#053F2E" />
      </div>

      <div className="sm:w-[270px] w-[170px] mb-10">
        <img
          src="/images/notiwero/update_img.svg"
          alt="Imagen newslatter"
          className="object-cover w-full"
        />
      </div>

      <div className="space-y-4 flex flex-col justify-center items-center w-full">
        <h4 className="md:text-3xl text-2xl font-bold text-center">
          Actualización de{" "}
          <span className="text-colorGreen">correo electrónico</span>
        </h4>

        <div className="space-y-3 flex flex-col items-center justify-center">
          <p className="md:text-base text-sm text-center ">
            Introduce tu nueva dirección de{" "}
            <span className="text-colorGreen font-semibold">
              correo electrónico
            </span>{" "}
            y el{" "}
            <span className="text-colorGreen font-semibold">
              codigo de verificación
            </span>{" "}
            <br /> que enviamos previamente a{" "}
            <span className="text-colorGreen font-semibold">
              {currentEmail}.
            </span>
          </p>
          <p className="md:text-base text-sm text-center">
            Revisa tu bandeja de entrada o spam.
          </p>
        </div>
      </div>

      <div className="md:w-2/3 w-full flex flex-row justify-center items-center ">
        <Form
          onSubmit={handleSubmit(dataForm)}
          textButton="Actualizar"
          classButton="bg-colorGreen sm:w-[255px] w-[220px] text-white"
          containerButton="flex w-full justify-center items-center"
          styleIsValid="hover:border-2 hover:border-colorGreen hover:bg-transparent hover:text-colorGreen"
          isValid={isValid}
          loading={loading}
        >
          <div className=" space-y-10 mt-10">
            <Input
              name="newEmail"
              type="email"
              placeholder="Escribe aquí tu correo electrónico"
              textLabel="Nuevo correo electrónico"
              register={register}
              errors={{ ...errors }}
              classInput="input-line-green text-colorGray text-center"
              classLabel="text-colorGreen text-center"
              containerInput="xl:w-[380px] lg:w-[370px] md:w-[330px]"
            />
            <InputNumber
              name="confirmationCode"
              placeholder="Escribe aquí el código de verificación"
              textLabel="Código de verificación"
              register={register}
              errors={{ ...errors, serverError }}
              classInput="input-line-green text-colorGray text-center"
              classLabel="text-colorGreen text-center"
              containerInput="xl:w-[380px] lg:w-[370px] md:w-[330px]"
            />
          </div>
        </Form>
      </div>

      <ModalConfirmationTemplate
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        className="hidden"
      >
        <h2 className="text-2xl text-colorGreen font-semibold text-center">
          ¡Actualización Exitosa!
        </h2>
        <p className="text-center text-base">
          A partir de ahora, el{" "}
          <span className="text-colorGreen font-semibold">newslatter</span> se
          enviará a su nueva dirección de correo electrónico.
        </p>
      </ModalConfirmationTemplate>
    </div>
  );
};
