export const ButtonsActions = () => {
  const buttons = [
    {
      text: "Wero Wisdom",
      link: "/wero-wisdom",
      color: "bg-colorDarkBlue",
      textColor: "text-white",
    },{
      text: "WATAFUK",
      link: "/tienda",
      color: "bg-colorDarkBlue",
      textColor: "text-white",
    },
    {
      text: "Mis Bisness",
      link: "/mis-bisness",
      color: "bg-white",
      textColor: "text-colorDarkBlue",
    },
  ];
  return (
    <section className="sm:space-y-14 space-y-10">
      <div className="flex flex-col items-center space-y-10">
        <p className="md:text-3xl text-xxl text-colorYellow text-center font-noto">
          "Quiero ser la chispa que encienda la revolución emprendedora y
          transformar ideas en negocios exitosos".
        </p>
      </div>
      <div className="flex flex-col items-center space-y-10">
        {buttons.map((button, index) => (
          <a
            key={index}
            href={button.link}
            className={`btn-yellow w-[250px] sm:h-16 uppercase`}
          >
            {button.text}

          </a>
        ))}
      </div>
    </section>
  );
};
