import { Logos } from "../../assets/Logos";

export default function Footer() {
  return (
    <div className="bg-colorGray w-full py-10 flex flex-col items-center justify-center lg:px-28 px-6 space-y-10">
      <div className="w-full flex lg:flex-row flex-col items-center lg:justify-between justify-center lg:space-y-0 space-y-12 ">
        <div className="lg:w-[106px] w-[88px]">
          <Logos fill="#F3FF03" />
        </div>

        <div className="flex sm:flex-row sm:space-x-6 flex-col">
          <a href="/#" target="_blank" rel="noreferrer noopener" className="text-white font-normal text-[16px] hover:text-colorYellow transition-colors duration-300 ">
            Política de privacidad
          </a>
          <a href="/#" target="_blank" rel="noreferrer noopener" className="text-white font-normal text-[16px] hover:text-colorYellow transition-colors duration-300">
            Términos y condiciones
          </a>
        </div>
      </div>

      <div className=" border-t-[1px] border-colorSand/20 w-full">
        <p className="text-colorSand text-center mt-8 text-base lg:mr-20">Bodob Capital LLC</p>
      </div>
    </div>
  );
}
