import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { Hero } from "../../components/notiwero/Hero";
import SEO from "../../seo/SEO";
import { Grids } from "../../components/notiwero/Grids";
import { Banner } from "../../components/notiwero/Banner";
import { SuscriptionPlan } from "../../components/notiwero/SuscriptionPlan";
import { FAQsNotiwero } from "../../components/notiwero/FAQsNotiwero";

export default function Notiwero() {
  return (
    <>
      <SEO
        title="Notiwero"
        description="Tu dosis diaria de noticias, sin rodeos ni aburrimiento. Información cruda, directa y con el toque único del Wero."
      />
      <section className="bg-colorSand/50">
        <Navbar
          color="#053F2E"
          bgColor="bg-colorSand1"
          colorTxt="text-colorGray"
          colorIcon="#343434"
        />
        <div>
          <Hero rute="/notiwero/wtf-notiwero" text="WTF con el NotiWero" />
          <Banner />
          <Grids />
          <SuscriptionPlan /> 
          <FAQsNotiwero />
        </div>
        <Footer />
      </section>
    </>
  );
}

// export default function Notiwero() {
//   return (
//     <>
//     <SEO title="Notiwero" description="Tu dosis diaria de noticias, sin rodeos ni aburrimiento. Información cruda, directa y con el toque único del Wero." />
//       <section className="bg-colorSand/50">
//         <Navbar
//           color="#053F2E"
//           bgColor="bg-colorSand1"
//           colorTxt="text-colorGray"
//           colorIcon="#343434"
//         />
//         <div className="md:py-36 sm:py-32 py-28 xl:px-28 lg:px-24 px-6 sm:space-y-20 space-y-10">
//           <Hero rute="/wtf-notiwero" text="WTF con el NotiWero" />
//           <FramePDF />
//         </div>
//         <Banner />
//         <Footer />
//       </section>
//     </>
//   );
// }
