import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/components/calendar.css";
import "./styles/components/inputs.css";
import "./styles/components/buttons.css";
import "./styles/components/selects.css";
import "./styles/components/textarea.css";
import ReactGA from "react-ga4";
import Home from "./pages/public/Home";
import Intro from "./pages/public/Intro";
import About from "./pages/public/About";
import Store from "./pages/public/Store";
import Notiwero from "./pages/public/Notiwero";
import { WTFNotiwero } from "./components/notiwero/WTFNotiwero";
import { Courses } from "./components/coaching/Courses";
import { CoachingUnoUno } from "./components/coaching/CoachingUnoUno";
import { Webinars } from "./components/coaching/Webinars";
import Error404 from "./pages/public/404";
import { ProcesPay } from "./components/coaching/coachingUno/pay/ProcesPay";
import { SuccesPay } from "./components/coaching/coachingUno/pay/SuccesPay";
import { Schudle } from "./components/coaching/coachingUno/pay/Schudle";
import { FormPay } from "./components/coaching/coachingUno/pay/FormPay";
import {ViewSuscriptionMonth} from "./components/notiwero/suscription/ViewsSuscription";
import { SuccessSuscription } from "./components/notiwero/suscription/SuccessSuscription";
import { CancelSuscription } from "./components/notiwero/suscription/CancelSuscription";
import {
  ProtectedRouteExpired,
  ProtectedRouteNotiwero,
} from "./middleware/ProtectedRoutes";
import { ExpiredURL } from "./components/notiwero/suscription/ExpiredURL";
import { UpdateEmail } from "./components/notiwero/suscription/UpdateEmail";
import { useEffect } from "react";
import Linktree from "./pages/public/Linktree";
import WeroWisdom from "./pages/public/WeroWisdom";
import { PixelManager } from "./seo/PixelManager";

ReactGA.initialize("G-EDDTQRRXLM");

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
    }, 180000);

    return () => clearTimeout(timer);
  });

  return (
    <BrowserRouter>
      <>
        <PixelManager />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/intro" element={<Intro />} />
          <Route path="/el-wero" element={<About />} />
          <Route path="/notiwero/*" element={<NotiweroLayout />} />
          <Route path="/coaching/*" element={<CoachingLayout />} />
          <Route path="/tienda" element={<Store />} />
          <Route path="/mis-bisness" element={<Linktree />} />
          <Route path="/wero-wisdom" element={<WeroWisdom />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

const NotiweroLayout = () => (
  <Routes>
    <Route path="/" element={<Notiwero />} />
    <Route path="wtf-notiwero" element={<WTFNotiwero />} />
    <Route
      path="/suscripcion/notiwero-plan-mensual"
      element={
        //<ProtectedRoutePlans>
          <ViewSuscriptionMonth />
        //</ProtectedRoutePlans>
      }
    />
    <Route
      path="suscripcion-exitosa"
      element={
        // <ProtectedRouteNotiwero storageKey="customerEmail" expiredTime = {600000}>
          <SuccessSuscription />
        // </ProtectedRouteNotiwero>
      }
    />

    <Route
      path="actualizar-email/:paramURL"
      element={
        <ProtectedRouteNotiwero storageKey="emailUpdate" expiredTime={300000}>
          <UpdateEmail />
        </ProtectedRouteNotiwero>
      }
    />

    <Route
      path="cancelar-suscripcion/:paramURL"
      element={
        <ProtectedRouteNotiwero storageKey="emailCancel" expiredTime={300000}>
          <CancelSuscription />
        </ProtectedRouteNotiwero>
      }
    />
    <Route
      path="link-expirado"
      element={
        <ProtectedRouteExpired>
          <ExpiredURL />
        </ProtectedRouteExpired>
      }
    />
    <Route path="*" element={<Error404 />} />
  </Routes>
);

const CoachingLayout = () => {
  return (
    <Routes>
      <Route path="coaching-uno-a-uno/*" element={<CoachingUnoUnoLayout />} />
      <Route path="cursos" element={<Courses />} />
      <Route path="webinars" element={<Webinars />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

const CoachingUnoUnoLayout = () => {
  return (
    <Routes>
      <Route path="/" element={<CoachingUnoUno />} />
      <Route path="/cuentame-de-ti" element={<ProcesPay />} />
      <Route path="agenda-tus-citas" element={<Schudle />} />
      <Route path="pago" element={<FormPay />} />
      <Route path="success-pay" element={<SuccesPay />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default App;
