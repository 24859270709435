import { motion } from "framer-motion";
import { CheckIcon } from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import { useGetProducts } from "../../hooks/useFetch/useGetProducts";

export const SuscriptionPlan = () => {
  const navigate = useNavigate();
  const {products} = useGetProducts();

  return (
    <>
      <div
        id="suscripcion"
        className="bg-plansuscription bg-colorGreen min-h-screen xl:px-28 lg:px-24 px-6 md:py-36 sm:py-32 py-20 flex flex-col items-center justify-center w-full space-y-12 "
      >
        <div>
          <p className="text-white font-noto text-sm text-center">
            Como deber ser
          </p>
          <h4 className="text-white text-center">Plan sencillito</h4>
        </div>

        <div className="flex md:flex-row flex-col-reverse md:space-x-12 md:space-y-0 space-y-reverse space-y-12">

          {products.map((item) => (
            <div
              key={item.id}
              className={`${
                item.name === "Notiwero Plan Mensual"
                  ? "border-colorSand1 border-4"
                  : "border-colorSand1 border-2"
              } rounded-xl p-6 flex flex-col justify-between items-center xs:w-[350px] h-[490px]`}
            >
              <div className="flex flex-col justify-center items-center space-y-4">
                <div className="w-16">
                  <img src="/images/notiwero/icon_men.svg" alt={item.name} />
                </div>

                <div className="space-y-6">
                  <div className="space-y-1">
                    <p className="text-2xl text-white font-noto text-center uppercase">
                      {item.name === "Notiwero Plan Mensual"
                        ? "Mensual"
                        : "Anual"}
                    </p>
                    {item?.prices?.map((price) => (
                      <p
                        key={price.id}
                        className="text-white text-sm text-center"
                      >
                        ${price.price / 100}{" "}
                        <span className="uppercase">{price.currency}</span> al
                        mes
                      </p>
                    ))}
                  </div>

                  <div className="flex flex-row items-center space-x-3">
                    <div className="w-4">
                      <CheckIcon />
                    </div>
                    <div className="space-y-5">
                      <p className="font-semibold text-white text-sm text-center">
                        ¡Prueba gratis por 15 días!
                      </p>
                      <p className="text-white text-sm text-center">
                        {item.description}
                      </p>

                      <p className="text-white text-sm text-center">
                        Recíbelo de lunes a viernes <br />a las 10:30 a.m.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {item?.prices?.map((price) => (
                <motion.button
                  key={price.id}
                  onClick={() => {
                    navigate(
                      `/notiwero/suscripcion/notiwero-plan-mensual`
                    );
                  }}
                  className={`${
                    item.name === "Notiwero Plan Mensual"
                      ? "btn-yellow"
                      : "btn-line-white"
                  } w-[130px] rounded-lg h-[36px] text-[15px]`}
                >
                  Empezar
                </motion.button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
