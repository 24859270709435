import { CommingSonn } from "../../pages/public/CommingSoon";
import SEO from "../../seo/SEO";

export const Webinars = () => {
  return (
    <>
      <SEO
        title="Webinars"
        description="Sumérgete en conocimientos profundos y prácticos con los webinars de El Wero Bisnero. Aprende estrategias clave para tu éxito y conecta con una comunidad apasionada de emprendedores."
      />
      <CommingSonn title="Webinars" />
    </>
  );
};
