import { FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { CalendarIcon, CreditCardIcon, UserIcon } from "../assets/Icons";
import {  AlecramIcon, CasaArbolIcon, InteliteIcon, MediodeMediosIcon, OblekIcon, PaashIcon, Roof36Icon} from "../assets/BusinessIcons";


export const DataSectionGrids = [
  {
    id: 1,
    title: "Las Chingonas",
    description:
      "Aquí es donde el buen humor manda. Noticias que te harán sonreír aunque hayas pisado un Lego descalzo. Desde gatitos salvadores hasta abuelitas que rompen récords en maratones. Si te saca una carcajada, aquí va.",
    bg: "bg-colorGreen",
    colorText: "text-colorYellow",
    colorText1: "text-colorGray1",
    colorIcon: "text-colorGreen",
    bgIcon: "bg-colorSand",
  },
  {
    id: 2,
    title: "Las bisneras",
    description:
      "El pan nuestro de cada día para los que sueñan con ser su propio jefe. Noticias crujientes sobre economía, finanzas, y emprendimientos más hot que un tamal. Si es de negocios y no está aquí, probablemente sea tan aburrido que ni tu contador lo lee.",
    bg: "bg-colorGray1",
    colorText: "text-colorGreen",
    colorText1: "text-colorGray2",
    colorIcon: "text-colorYellow",
    bgIcon: "bg-colorGreen",
  },
  {
    id: 3,
    title: "Lo geek",
    description:
      "Para los que saben que la realidad a veces necesita un upgrade. Noticias de tecnología, videojuegos, y todo lo que le interesa a tu primo el programador. Desde la última consola hasta avances en IA que te hacen cuestionar si vivimos en Matrix.",
    bg: "bg-colorGreen",
    colorText: "text-colorYellow",
    colorText1: "text-colorGray1",
    colorIcon: "text-colorGreen",
    bgIcon: "bg-colorSand",
  },
  {
    id: 4,
    title: "La polaca",
    description:
      "Aquí se cocina la política más enredada que un plato de espaguetis. Desde los chismes de la política mexicana hasta los dramas internacionales. Si buscas un reality show de verdad, olvídate de la tele y sintoniza aquí.",
    bg: "bg-colorGray1",
    colorText: "text-colorGreen",
    colorText1: "text-colorGray2",
    colorIcon: "text-colorYellow",
    bgIcon: "bg-colorGreen",
  },
  {
    id: 5,
    title: "Globósfera",
    description:
      "Porque hay vida más allá de tu colonia. Noticias internacionales con más variedad que un buffet de tacos. Si pasó en el mundo y vale la pena saberlo, aquí te enteras primero.",
    bg: "bg-colorGreen",
    colorText: "text-colorYellow",
    colorText1: "text-colorGray1",
    colorIcon: "text-colorGreen",
    bgIcon: "bg-colorSand",
  },
  {
    id: 6,
    title: "No te aburras",
    description:
      "Cultura y entretenimiento, porque no solo de pan vive el hombre. Noticias de conciertos, museos, exposiciones y todo lo que necesitas para ser el alma de la fiesta. Si es divertido y está pasando, aquí lo cubrimos.",
    bg: "bg-colorGray1",
    colorText: "text-colorGreen",
    colorText1: "text-colorGray2",
    colorIcon: "text-colorYellow",
    bgIcon: "bg-colorGreen",
  },
];

export const DataTickerImage = [
  {
    src: "/images/about/IMG-01.png",
    alt: "El wero bisnero 1",
  },
  {
    src: "/images/about/IMG-02.png",
    alt: "El wero bisnero 2",
  },
  {
    src: "/images/about/IMG-03.png",
    alt: "El wero bisnero 3",
  },
  {
    src: "/images/about/IMG-04.png",
    alt: "El wero bisnero 4",
  },
  {
    src: "/images/about/IMG-05.png",
    alt: "El wero bisnero 5",
  },
  {
    src: "/images/about/IMG-06.png",
    alt: "El wero bisnero 6",
  },
  {
    src: "/images/about/IMG-07.png",
    alt: "El wero bisnero 7",
  },
  {
    src: "/images/about/IMG-08.png",
    alt: "El wero bisnero 8",
  },
  {
    src: "/images/about/IMG-09.png",
    alt: "El wero bisnero 9",
  },
  {
    src: "/images/about/IMG-10.png",
    alt: "El wero bisnero 10",
  },
  {
    src: "/images/about/IMG-11.png",
    alt: "El wero bisnero 11",
  },
  {
    src: "/images/about/IMG-12.png",
    alt: "El wero bisnero 12",
  },
  {
    src: "/images/about/IMG-13.png",
    alt: "El wero bisnero 13",
  },
  {
    src: "/images/about/IMG-14.png",
    alt: "El wero bisnero 14",
  },
  {
    src: "/images/about/IMG-15.png",
    alt: "El wero bisnero 15",
  },
  {
    src: "/images/about/IMG-16.png",
    alt: "El wero bisnero 16",
  },
  {
    src: "/images/about/IMG-17.png",
    alt: "El wero bisnero 17",
  },
  {
    src: "/images/about/IMG-18.png",
    alt: "El wero bisnero 18",
  },
  {
    src: "/images/about/IMG-19.png",
    alt: "El wero bisnero 19",
  },
  {
    src: "/images/about/IMG-20.png",
    alt: "El wero bisnero 20",
  },
  {
    src: "/images/about/IMG-21.png",
    alt: "El wero bisnero 21",
  },
  {
    src: "/images/about/IMG-22.png",
    alt: "El wero bisnero 22",
  },
  {
    src: "/images/about/IMG-23.png",
    alt: "El wero bisnero 23",
  },
  {
    src: "/images/about/IMG-24.png",
    alt: "El wero bisnero 24",
  },
  {
    src: "/images/about/IMG-25.png",
    alt: "El wero bisnero 25",
  },
  {
    src: "/images/about/IMG-27.png",
    alt: "El wero bisnero 27",
  },
  {
    src: "/images/about/IMG-28.png",
    alt: "El wero bisnero 28",
  },
  {
    src: "/images/about/IMG-29.png",
    alt: "El wero bisnero 29",
  },
  {
    src: "/images/about/IMG-30.png",
    alt: "El wero bisnero 30",
  },
  {
    src: "/images/about/IMG-31.png",
    alt: "El wero bisnero 31",
  },
  {
    src: "/images/about/IMG-32.png",
    alt: "El wero bisnero 32",
  },
  {
    src: "/images/about/IMG-33.png",
    alt: "El wero bisnero 33",
  },
  {
    src: "/images/about/IMG-34.png",
    alt: "El wero bisnero 34",
  },
  {
    src: "/images/about/IMG-36.png",
    alt: "El wero bisnero 36",
  },
];

export const DataBenefitsCoaching = [
  {
    title: "Experiencia y risas",
    description:
      "Más de 10 años en el negocio, con un toque de humor que te hará disfrutar el viaje.",
  },
  {
    title: "Personalización",
    description:
      "Cada idea es única, y así es como la tratamos. Nada de cortar y pegar.",
  },
  {
    title: "Red de Contactos",
    description:
      "Acceso a una red de expertos en diferentes áreas del negocio.",
  },
  {
    title: "Mentalidad Ganadora",
    description:
      "No solo te enseñamos a hacer negocio, sino a pensar como un verdadero tiburón de las finanzas.",
  },
  {
    title: "Soporte Continuo",
    description:
      "No te dejamos tirado a medio camino. Estamos contigo hasta que tu negocio despegue.",
  },
];

export const DataSesionsCoaching = [
  {
    title: "Sesiones presenciales",
    description:
      "El paquete Presencial es para aquellos emprendedores que prefieren la energía de un encuentro cara a cara. Durante 10 sesionesintensivas de una hora y media cada una, trabajaremos juntos en mi espacio, puliendo tu idea, elaborando un plan de negocios detallado y definiendo un plan de acción claro. Este paquete es perfecto si buscas un enfoque personal y directo, donde cada detalle de tu proyecto se discute y perfecciona en persona.",
    labelbtn: "¡Empecemos!",
    range: "Curso limitado a 5 personas",
    bg: "bg-colorGreen",
    colorTitle: "text-colorYellow",
    colorText: "text-colorSand",
    btn: "btn-white",
    colorLabel: "text-colorSand",
  },
  {
    title: "Sesiones virtuales",
    description:
      "El paquete Virtual te ofrece la flexibilidad y comodidad de trabajar desde cualquier lugar del mundo. A través de 10 sesiones de una hora y media por videoconferencia, te guiaré en el desarrollo de tu idea, la creación de un plan de negocios robusto y la definición de un plan de acción estratégico. Ideal para emprendedores dinámicos y modernos que buscan maximizar su tiempo y recursos, aprovechando la tecnología para acelerar su éxito.",
    labelbtn: "¡Empecemos!",
    range: "Curso limitado a 5 personas",
    bg: "bg-colorGray3",
    colorTitle: "text-colorGreen",
    colorText: "text-colorGray2",
    btn: "btn-greenY",
    colorLabel: "text-colorGreen",
  },
];

export const dataStepper = [
  {
    title: "Datos",
    icon: <UserIcon fill="#fff" />,
    href: "/datos",
  },
  {
    title: "Calendario",
    icon: <CalendarIcon fill="#748596" secondFill="#fff" />,
    href: "/agenda",
  },
  {
    title: "Pago",
    icon: <CreditCardIcon fill="#fff" />,
    href: "/pago",
  },
];

export const dataGridsNotiwero = [
  {
    title: "Sin fake news",
    description:
      "Tu wingman de la primicia real de los eventos globales. Verificamos todo para que te sientes, te relajes y disfrutes sin preocuparte por la desinformación. ¡Mantente informado, entretenido y sin estrés!",
  },
  {
    title: "Sin influenciar a lectores",
    description:
      "Mi objetivo es que recibas información objetiva e imparcial, lo que te permita formar tus propias opiniones sin ninguna influencia externa de las notas más importantes del mundo.",
  },
  {
    title: "Sin rodeos ni aburrimiento",
    description:
      "Directo al grano, sin scrolls interminables, sin bostezos, solo la información esencial que necesitas en tu bandeja de entrada.",
  },
  {
    title: "Calidad de lectura",
    description:
      "La calidad de lectura de primer nivel que hace que mantenerse informado sea un placer. Seleccionamos cuidadosamente las historias más jugosas de todo el mundo, asegurándonos de que cada nota sea una lectura sencilla y rápida para estar al día.",
  },
  {
    title: "Directo a tu bandeja de entrada",
    description:
      "Imagínate despertarte con una dosis diaria de actualizaciones globales, frescas y listas para tu café de la mañana. Es como tener un mayordomo de noticias personal. ¡Siéntate, relájate y deja que las historias más importantes del mundo lleguen a ti!",
  },
  {
    title: "Todos los días a las 10:30 a.m",
    description:
      "Tu ritual nocturno de ponerte al día con todo lo que importa, justo a tiempo, siempre, brindándote un resumen conciso y cautivador de las noticias más importantes del mundo. Así que, ¡relájate, y déjanos mantenerte informado!",
  },
];

export const dataFAQsNotiwero = [
  {
    question: "¿Qué es el NotiWero?",
    answer:
      "Es un Newsletter especializado que se envía todos los días a las 8:00pm, contiene contenido de alta calidad de las noticias más importantes del mundo sobre todo lo relacionado a negocios y emprendimiento.",
  },
  {
    question: "¿Qué temas cubre el NotiWero?",
    answer:
      "TODO sobre negocios y emprendimiento de México y del mundo, finanzas, economía, tecnología, política, y también sobre otras noticias interesantes y divertidas para relajarte mientras recibes tu dosis diaria de noticias.",
  },
  {
    question: "¿Cómo actualizo mi correo para recibir el Newsletter?",
    answer:
      "No te preocupes todo tiene solución, si necesitas cambiar o actualizar tu correo ligado al Newsletter del NotiWero haz click en el link debajo.",
    button: [
      {
        id: 1,
        type: "actualizar",
        text: "Actualizar correo electrónico",
      },
    ],
  },
  {
    question: "¿Qué debo hacer si no recibo el Newsletter?",
    answer:
      "Revisa en tu bandeja de spam si los correos están llegando ahí, si es el caso, en el mismo correo puedes anular esa acción para que te lleguen a tu bandeja de entrada sin problema. Si ese no es el caso, actualiza tu correo con el link de la pregunta de arriba “Cómo actualizo mi correo para recibir el Newsletter” ",
  },
  {
    question:
      "¿Qué hago si he perdido el acceso al correo electrónico en el que recibo mi suscripción",
    answer:
      "Si no puedes recuperar tu cuenta de correo electrónico, ponte en contacto con nuestro equipo de soporte. Incluye la siguiente información: Nombre completo, correo electrónico antiguo, correo electrónico nuevo, detalles sobre tu suscripción (fecha de suscripción, etc.) al Newsletter. Contáctanos a través de",

    email: "soporte@werobisnero.com.",
  },
  {
    question: "¿Cómo cancelo suscripción al Newsletter?",
    answer:
      "Para cancelar tu suscripción haz click en el link debajo y sigue las instrucciones.",
    button: [
      {
        id: 2,
        type: "cancelar",
        text: "Cancelar suscripción",
      },
    ],
  },
  {
    question: "¿Cuál es la política de devolución?",
    answer:
      "Entendemos que puedan existir errores técnicos, errores con la facturación o insatisfacción con el servicio, por ende existe una ventana de 10 días después de suscribirte o cobro por el servicio del Newsletter, para solicitar un reembolso.",
  },
];

export const myBisness = [
  {
    red: "Intélite",
    url: "https://intelite.mx/",
    icon: <InteliteIcon />,
    styleIcon:"w-52"
  },
  {
    red: "El Medio de Medios",
    url: "https://www.elmediodemedios.com/",
    icon: <MediodeMediosIcon />,
    styleIcon:"w-60"
  },
  {
    red: "Casa del árbol",
    url: "https://www.airbnb.mx/rooms/36600997",
    icon: <CasaArbolIcon />,
    styleIcon:"w-32"
  },
  {
    red: "Roof 36",
    url: "https://www.instagram.com/36rooftop/",
    icon: <Roof36Icon />,
    styleIcon:"w-48"
  },
  {
    red: "WTF",
    url: "/tienda",
    username: "@elwerobisnero",
    styleIcon:"w-48"
  },
  {
    red: "Paash",
    url: "https://www.instagram.com/paashcommunity/",
    icon: <PaashIcon />,
    username: "@elwerobisnero",
    styleIcon:"w-24"
  },
  {
    red: "Oblek",
    url: "mailto:hola@oblek.com",
    icon: <OblekIcon />,
    username: "@elwerobisnero",
    styleIcon:"w-56"
  },
  {
    red: "ALECRAM",
    url: "mailto:alecramrsb@gmail.com",
    icon: <AlecramIcon />,
    username: "@elwerobisnero",
    styleIcon:"w-48"
  },
  {
    red: "Flipping",
    url: "https://wa.me/525569308704",
    username: "@elwerobisnero",
  },
];

export const shareMedia = [
  {
    Component: FacebookShareButton,
    icon: "fab fa-facebook-f",
    name: "Facebook",
    props: {
      hashtag: "#!Unete a la comunidad Bisnera! 💰💸",
    },
  },
  {
    Component: TwitterShareButton,
    icon: "fab fa-x-twitter",
    name: "Twitter",
    props: {
      title: "!Unete a la comunidad Bisnera! 💰💸 \n",
      hashtags: ["Bisneros", "ElWeroBisnero", "Coaching", "DesarrolloPersonal", "FinanzasPersonales", "Notiwero"],
    },
  },
  {
    Component: LinkedinShareButton,
    icon: "fab fa-linkedin-in",
    name: "Linkedin",
    props: {
      title: "!Unete a la comunidad Bisnera! 💰💸",
      summary: "No te pierdas las últimas novedades",
      source: "https://elwerobisnero.com",
    },
  },
  {
    Component: WhatsappShareButton,
    icon: "fab fa-whatsapp",
    name: "Whatsapp",
    props: {
      title: "*!Unete a la comunidad Bisnera! 💰💸!*\nNo te pierdas las últimas novedades\n\n",
    },
  },
  {
    Component: TelegramShareButton,
    icon: "fab fa-telegram-plane",
    name: "Telegram",
    props: {
      title: "\n\n**!Unete a la comunidad Bisnera! 💰💸!**\nNo te pierdas las últimas novedades",
    },
  },
];
