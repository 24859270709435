import {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
  TwitterIcon,
  YouTubeIcon,
} from "../../assets/SocialIcons";

export const ContactAbout = () => {
  return (
    <>
      <section className="xl:px-28 lg:px-24 px-6 md:py-36 sm:py-32 py-20 flex md:flex-row flex-col md:justify-between md:space-x-7 space-x-0 md:space-y-0 space-y-20">
        <div className="space-y-10 md:max-w-md">
          <div className="">
            <p className="text-4xl relative font-noto font-light">
              <span className="text-colorGreen font-medium">Soy tu coach</span>{" "}
              disruptivo en los{" "}
              <span
                className="relative
              "
              >
                negocios{" "}
                <img
                  src="images/app/shadow_line.png"
                  alt="Shadow line"
                  className="absolute -bottom-5 right-0  md:w-[275px] w-[172px] rotate-[5deg]"
                />
              </span>
            </p>
          </div>

          <div className="flex 2xl:flex-row flex-col 2xl:space-x-7 2xl:space-y-0 space-y-7">
            <div className="flex space-x-1">
              <div className="lg:w-[54px] w-[47px]">
                <FacebookIcon fill="#053F2E" color="#F2EFDD" />
              </div>
              <div className="lg:w-[54px] w-[47px]">
                <InstagramIcon fill="#053F2E" color="#F2EFDD" />
              </div>
              <div className="lg:w-[54px] w-[47px]">
                <TwitterIcon fill="#053F2E" color="#F2EFDD" />
              </div>
              <div className="lg:w-[54px] w-[47px]">
                <YouTubeIcon fill="#053F2E" color="#F2EFDD" />
              </div>
              <div className="lg:w-[54px] w-[47px]">
                <TikTokIcon fill="#053F2E" color="#F2EFDD" />
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-10 2xl:max-w-2xl xl:max-w-xl md:max-w-lg">
          <p>
            Mi rol como{" "}
            <span className="text-colorGreen font-medium">El Wero Bisnero</span>{" "}
            es aportar conocimiento en el mundo del emprendimiento, haciendo de
            conceptos complejos{" "}
            <span className="text-colorGreen font-medium">
              negocios accesibles y entretenidos
            </span>{" "}
            con un estilo directo mezclado con humor chilango.
          </p>
          <p>
            Mi contenido en redes sociales no solo busca educar, sino{" "}
            <span className="text-colorGreen font-medium">
              inspirar y desafiar
            </span>{" "}
            a la audiencia a tomar riesgos calculados y buscar su
            <span className="text-colorGreen font-medium">
              {" "}
              independencia financiera
            </span>{" "}
            para ser sus propios jefes.
          </p>
          <p>¿Mi filosofía? Simple:</p>

          <h1 className="text-4xl">
            "<span className="text-colorGreen font-medium">Emprende</span> con
            astucia,{" "}
            <span className="text-colorGreen font-medium">ejecuta</span> con
            acción y{" "}
            <span className="text-colorGreen font-medium">triunfa</span> con
            actitud".
          </h1>

          <a
            href="https://wa.me/525569308704"
            rel="noopener noreferrer"
            target="_blank"
            className="btn-green w-[196px]"
          >
            Contáctame
          </a>
        </div>
      </section>
    </>
  );
};
