export const LandingWisdom = () => {
  return (
    <section className="bg-linktree bg-[#e0e0e0] min-h-screen py-10 flex flex-col justify-center items-center space-y-10 px-6">
      <h3 className="font-work font-bold text-center">Wero Wisdom</h3>
      <div className="grid grid-cols-1 gap-7">
        <button onClick={() => donate()} className="bg-white p-4 rounded-3xl flex sm:flex-row flex-col sm:space-y-0 space-y-3 items-center h-auto max-w-2xl space-x-5 hover:scale-105 transition-transform duration-300">
          <div className="md:w-60 sm:w-44 w-32">
            <img
              src="https://cdn-icons-png.flaticon.com/512/3127/3127430.png"
              alt="Invita un café"
              className="object-cover w-full rounded-full bg-cyan-600"
            />
          </div>
          <div className="w-full">
            <h4 className="font-semibold text-center">Invítame un café</h4>
            <p className="text-center text-base sm:max-w-sm">Donación</p>
          </div>
        </button>

        {links.map((link, index) => (
          <button
            key={index}
            onClick={() => sendWhatsapp({ time: link.time, subject: link.title })}
            className="bg-white p-4 rounded-3xl flex sm:flex-row flex-col sm:space-y-0 space-y-3 items-center h-auto max-w-2xl space-x-5 hover:scale-105 transition-transform duration-300"
          >
            <div className="md:w-60 sm:w-44 w-32 ">
              <img
                src={link.icon}
                alt={link.title}
                className="object-cover w-full rounded-full bg-cyan-600"
              />
            </div>
            <div className="w-full flex flex-col items-center">
              <h4 className="font-semibold text-center">{link.title}</h4>
              <p className="text-center text-base sm:max-w-sm">{link.description}</p>
            </div> 
          </button>
        ))}
      </div>
    </section>
  );
};

const donate = () => {
  const url = "https://donate.stripe.com/6oE29Sbg3cGgdtmaEG";
  window.open(url, "_blank", "noopener,noreferrer");
};

const sendWhatsapp = ({ time, subject }) => {
  const msg = `*Asunto:* ${subject}%0A%0AHola 👋🏼, me gustaría agendar una sesión de asesoría/coaching para **${time}**. 🗓️✨`;
  const phoneNumber = "5569308704"; 
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${msg}`;
  window.open(url, "_blank", "noopener,noreferrer");
};

const links = [
  {
    title: "Invítame una burguer",
    description: "Agenda 30 minutos de sesión de asesoría / coaching",
    time: "30m",
    url: "/wero-wisdom",
    icon: "https://png.pngtree.com/png-clipart/20221002/original/pngtree-hamburger-icon-png-image_8651865.png",
  },
  {
    title: "Invítame una pizza",
    description: "Agenda 1 hora y 30 minutos de sesión de asesoría / coaching",
    time: "1h 30m",
    url: "/wero-wisdom",
    icon: "https://cdn-icons-png.flaticon.com/512/4727/4727378.png",
  },
];
